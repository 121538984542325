import { Injectable } from '@angular/core';
import { ViewMode } from 'app/entities/user';
import { HttpService } from 'app/services/http.service';

import { UserStore } from '.';

@Injectable({ providedIn: 'root' })
export class UserStateService {
    constructor(private userStore: UserStore, private httpService: HttpService) {}

    updateUser(): void {
        this.userStore.updateUser();
    }

    async setViewMode(viewMode: ViewMode): Promise<void> {
        await this.httpService.postAuth('users/set-view', { viewMode });
        this.userStore.updateUser();
    }

    async setOrg(organisationId: number): Promise<void> {
        await this.httpService.postAuth('users/set-organisation', { organisationId });
        this.httpService.updateCurrentOrgId(organisationId);
        this.userStore.updateUser();
    }

    async setSchool(schoolId: number): Promise<void> {
        await this.httpService.postAuth('users/set-school', { schoolId });
        this.httpService.updateCurrentSchoolId(schoolId);
        this.userStore.updateUser();
    }

    async setCampus(campusId: number | null): Promise<void> {
        await this.httpService.postAuth('users/set-campus', { campusId });
        this.userStore.updateUser();
    }

    async setSpecificCampus(specificCampusId: number): Promise<void> {
        await this.httpService.postAuth('users/set-specific-campus', { specificCampusId });
        this.userStore.updateUser();
    }

    resetStore() {
        return this.userStore.reset();
    }
}
