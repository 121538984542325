import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, InjectionToken, NgModule } from '@angular/core';
import { FormsModule, NG_VALIDATORS, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import {
    MAT_LEGACY_CHECKBOX_DEFAULT_OPTIONS as MAT_CHECKBOX_DEFAULT_OPTIONS,
    MatLegacyCheckboxDefaultOptions as MatCheckboxDefaultOptions,
    MatLegacyCheckboxModule as MatCheckboxModule,
} from '@angular/material/legacy-checkbox';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatSortModule } from '@angular/material/sort';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Angular2PromiseButtonModule } from 'angular2-promise-buttons';
import { ngfModule } from 'angular-file';
import { ChartLegendComponent } from 'app/components/chart-legend/chart-legend.component';
import { ChartwrapperComponent } from 'app/components/chart-wrapper/chart-wrapper.component';
import { PieZingChartComponent } from 'app/components/pie-zing-chart/pie-zing-chart.component';
import { RelatedContactsListComponent } from 'app/students/student-page/related-contacts-list/related-contacts-list.component';
import { emailValidator } from 'app/validators/email.validator';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';
import { ZingchartAngularModule } from 'zingchart-angular';

import { AddressPipe } from '../common/pipes/address.pipe';
import { SharedModule } from '../shared.module';
import { AddContactModalComponent } from './add-contact-modal/add-contact-modal.component';
import { AddCurrentSchoolDialogComponent } from './add-current-school-dialog/add-current-school-dialog';
import { AddListItemDialogComponent } from './add-list-item-dialog/add-list-item-dialog';
import { AddOrEditContactModalComponent } from './add-or-edit-contact-modal/add-or-edit-contact-modal.component';
import { AddOrEditContactComponent } from './add-or-edit-contact/add-or-edit-contact.component';
import { BFilterConstellationComponent } from './b-filter-constellation/b-filter-constellation.component';
import { BarChartColComponent } from './bar-chart-col/bar-chart-col.component';
import { BarChartGroupedComponent } from './bar-chart-grouped/bar-chart-grouped.component';
import { BarChartStackedHorizontalComponent } from './bar-chart-stacked-horizontal/bar-chart-stacked-horizontal.component';
import { BarChartStackedComponent } from './bar-chart-stacked/bar-chart-stacked.component';
import { BarChartComponent } from './bar-chart/bar-chart.component';
import { BulkEditStudentComponent } from './bulk-edit-dialog/bulk-edit-student.component';
import { ChartActionSectionComponent } from './chart-action-section/chart-action-section.component';
import { ChartButtonGroupComponent } from './chart-button-group/chart-button-group.component';
import { ChartMetaDataComponent } from './chart-meta-data/chart-meta-data.component';
import { CheckboxGroupComponent } from './checkbox-group/checkbox-group.component';
import { ColumnChartComponent } from './column-chart/column-chart.component';
import { CopyPassword } from './copy-password/copy-password.component';
import { DownloadDialog } from './download-dialog/download-dialog.component';
import { DuplicateNameDialog } from './duplicate-name-dialog/duplicate-name-dialog.component';
import { EditContactComponent } from './edit-contact/edit-contact.component';
import { EditStudentModalComponent } from './edit-student-modal/edit-student-modal.component';
import { EditStudentComponent } from './edit-student/edit-student.component';
import { EnquiryComponent } from './edit-student/enquiry.component';
import { StudentProfileComponent } from './edit-student/student-profile.component';
import { EmailChipsComponent } from './email-chips/email-chips.component';
import { EmailScheduleComponent } from './email-schedule/email-schedule.component';
import { BulkSendAppEmailComponent } from './email-send-dialog/bulk-send-dialog/bulk-send-app-email.component';
import { BulkSendEventEmailComponent } from './email-send-dialog/bulk-send-dialog/bulk-send-event-email.component';
import { BulkSendStudentEmailComponent } from './email-send-dialog/bulk-send-dialog/bulk-send-student-email.component';
import { EmailSignatureComponent } from './email-signature/email-signature.component';
import { AppEmailTemplateComponent } from './email-template/app-email-template.component';
import { BulkEmailTemplateComponent } from './email-template/bulk-email-template.component';
import { EditEmailTemplateComponent } from './email-template/edit-email-template/edit-email-template.component';
import { EventEmailTemplateBaseDirective } from './email-template/event-email-template-base.directive';
import { EventEmailTemplateComponent } from './email-template/event-email-template.component';
import { PTEmailTemplateComponent } from './email-template/pt-email-template.component';
import { GeneralEmailTemplateComponent } from './email-template/webform-email-template/general-email-template.component';
import { ProspectusEmailTemplateComponent } from './email-template/webform-email-template/prospectus-email-template.component';
import { EnquiryCountSnapshotsComponent } from './enquiry-count-snapshots/enquiry-count-snapshots.component';
import { ExportDialog } from './export-dialog/export-dialog.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { FilterConstellationComponent } from './filter-constellation/filter-constellation.component';
import { FilterSwitchComponent } from './filter-constellation/filter-switch/filter-switch.component';
import { BaseFilterComponent } from './filter-constellation/filters/base-filter/base-filter.component';
import { CheckBoxComponent } from './filter-constellation/filters/check-box/check-box.component';
import { ConditionalDropdownComponent } from './filter-constellation/filters/conditional-dropdown/conditional-dropdown.component';
import { DateRangePickerComponent } from './filter-constellation/filters/date-range-picker/date-range-picker.component';
import { DropDownComponent } from './filter-constellation/filters/drop-down/drop-down.component';
import { RadioButtonComponent } from './filter-constellation/filters/radio-button/radio-button.component';
import { TextBoxComponent } from './filter-constellation/filters/text-box/text-box.component';
import { YearMonthPickerComponent } from './filter-constellation/filters/year-month-picker/year-month-picker.component';
import { MoreOptionsComponent } from './filter-constellation/more-options/more-options.component';
import { DisplayFormHeaderFooterComponent } from './form-header-footer/display-form-header-footer/display-form-header-footer.component';
import { SetupFormHeaderFooterComponent } from './form-header-footer/setup-form-header-footer/setup-form-header-footer.component';
import { FunnelChartComponent } from './funnel-chart/funnel-chart.component';
import { FunnelMetricsChartComponent } from './funnel-metrics-chart/funnel-metrics-chart.component';
import { GoogleTranslateComponent } from './google-translate/google-translate.component';
import { GroupedTablePieChartComponent } from './grouped-table-pie-chart/grouped-table-pie-chart.component';
import { HeatMapChartComponent } from './heat-map-chart/heat-map-chart.component';
import { TableEndComponent } from './infinite-scroll/table-end/table-end.component';
import { TableSpinnerComponent } from './infinite-scroll/table-spinner/table-spinner.component';
import { TableStateInfoComponent } from './infinite-scroll/table-state-info/table-state-info.component';
import { LineAreaChartComponent } from './line-area-chart/line-area-chart.component';
import { LineChartComponent } from './line-chart/line-chart.component';
import { LineZingChartComponent } from './line-zing-chart/line-zing-chart.component';
import { GoogleMapComponent } from './map/map.component';
import { MergeContactsComponent } from './merge-enquiry/merge-contacts.component';
import { MergeRelatedContactsComponent } from './merge-enquiry/merge-related-contacts.component';
import { MergeRelatedStudentsComponent } from './merge-enquiry/merge-related-students.component';
import { MergeStudentsComponent } from './merge-enquiry/merge-students.component';
import { MergeCurrentSchoolComponent } from './merge-item/merge-current-school.component';
import { MergeListItemComponent } from './merge-item/merge-list-item.component';
import { MergeItemComponent } from './merge-item/merge.component';
import { AddOtherDialogComponent } from './other-mat-select/add-other-dialog';
import { OtherCurrentSchoolComponent } from './other-mat-select/other-current-school.component';
import { OtherListItemComponent } from './other-mat-select/other-list-item.component';
import { PersonalTourWidgetComponent } from './personal-tour-widget/personal-tour-widget.component';
import { PieChartColComponent } from './pie-chart-col/pie-chart-col.component';
import { PieChartTableComponent } from './pie-chart-table/pie-chart-table.component';
import { PieChartComponent } from './pie-chart/pie-chart.component';
import { PointViewComponent } from './point-view/point-view.component';
import { ProspectusesWidgetComponent } from './prospectuses-widget/prospectuses-widget.component';
import { ProtectedInputComponent } from './protected-input/protected-input.component';
import { RadioGroupComponent } from './radio-group/radio-group.component';
import { RelatedContactsForNewStudentComponent } from './related-contacts-for-new-student/related-contacts-for-new-student.component';
import { RelatedContactsComponent } from './related-contacts/related-contacts.component';
import { RelatedStudentsComponent } from './related-students/related-students.component';
import { ReviewRelatedContactsComponent } from './review-related-contacts/review-related-contacts.component';
import { ReviewRelatedStudentsComponent } from './review-related-students/review-related-students.component';
import { SchoolLogoComponent } from './school-logo/school-logo.component';
import { SelectContactModalComponent } from './select-contact-modal/select-contact-modal.component';
import { SelectSearchComponent } from './select-search/select-search.component';
import { SelectStudentModalComponent } from './select-student-modal/select-student-modal.component';
import { SelectUserDialogComponent } from './select-user-dialog/select-user-dialog.component';
import { SendTestEmailComponent } from './send-test-email/send-test-email.component';
import { SpinnerModule } from './spinner/spinner.module';
import { StatisticPanelComponent } from './statistic-panel/statistic-panel.component';
import { StripeIntegrationComponent } from './stripe-integration/stripe-integration';
import { StripeIntegrationModalComponent } from './stripe-integration/stripe-integration-modal/stripe-integration-modal';
import { StudentEnquirySwitcherComponent } from './student-enquiry-switcher/student-enquiry-switcher.component';
import { SubTourListComponent } from './sub-tour-list/sub-tour-list.component';
import { TableComponent } from './table/table.component';
import { TransferDialog } from './transfer-dialog/transfer-dialog.component';
import { OrgUserDetailsComponent } from './user-details/org-user-details.component';
import { SchoolUserDetailsComponent } from './user-details/school-user-details.component';
import { SystemUserDetailsComponent } from './user-details/system-user-details.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { OrgUsersComponent } from './users/org-users.component';
import { SchoolUsersComponent } from './users/school-users.component';
import { SystemUsersComponent } from './users/system-users.component';
import { ViewDetailsFooterComponent } from './view-details-footer/view-details-footer.component';
import { WidgetFilterComponent } from './widget-filter/widget-filter.component';
import { WidgetComponent } from './widget/widget.component';

@NgModule({
    imports: [
        CommonModule,
        MatMenuModule,
        RouterModule,
        GoogleMapsModule,
        Angular2PromiseButtonModule.forRoot({
            spinnerTpl: '<span class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>',
            handleCurrentBtnOnly: false, // Change to false to disable buttons until getting response
        }),
        FormsModule,
        ReactiveFormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
        MatSelectModule,
        MatInputModule,
        MatDatepickerModule,
        MatAutocompleteModule,
        MatTooltipModule,
        MatDialogModule,
        MatProgressBarModule,
        MatCheckboxModule,
        MatRadioModule,
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        MatDividerModule,
        MatButtonModule,
        ngfModule,
        NgxDaterangepickerMd,
        SharedModule,
        TranslateModule,
        MatChipsModule,
        MatIconModule,
        MatProgressSpinnerModule,
        NgxMatSelectSearchModule,
        SpinnerModule,
        MatTabsModule,
        ZingchartAngularModule,
        NgxMatTimepickerModule,
        EmailScheduleComponent,
        SchoolUsersComponent,
        SystemUsersComponent,
        SchoolUserDetailsComponent,
        SystemUserDetailsComponent,
        OrgUserDetailsComponent,
        OrgUsersComponent,
    ],
    declarations: [
        AddContactModalComponent,
        AddOrEditContactModalComponent,
        EditContactComponent,
        AddOrEditContactComponent,
        EditStudentComponent,
        EnquiryComponent,
        StudentProfileComponent,
        SelectContactModalComponent,
        SelectStudentModalComponent,
        PointViewComponent,
        StatisticPanelComponent,
        PieChartComponent,
        PieChartTableComponent,
        PieChartColComponent,
        LineChartComponent,
        TableComponent,
        FeedbackComponent,
        UserProfileComponent,
        BarChartComponent,
        PersonalTourWidgetComponent,
        ProspectusesWidgetComponent,
        GoogleMapComponent,
        BarChartGroupedComponent,
        BarChartColComponent,
        RelatedContactsComponent,
        RelatedContactsForNewStudentComponent,
        RelatedStudentsComponent,
        EditStudentModalComponent,
        AddListItemDialogComponent,
        AddOtherDialogComponent,
        AddCurrentSchoolDialogComponent,
        BarChartStackedComponent,
        BarChartStackedHorizontalComponent,
        SchoolLogoComponent,
        EmailSignatureComponent,
        SelectUserDialogComponent,
        AddressPipe,
        DropDownComponent,
        FilterConstellationComponent,
        BFilterConstellationComponent,
        BaseFilterComponent,
        MoreOptionsComponent,
        TextBoxComponent,
        DateRangePickerComponent,
        FilterSwitchComponent,
        CheckBoxComponent,
        YearMonthPickerComponent,
        RadioButtonComponent,
        FunnelChartComponent,
        FunnelMetricsChartComponent,
        LineZingChartComponent,
        SendTestEmailComponent,
        GroupedTablePieChartComponent,
        EmailChipsComponent,
        CheckboxGroupComponent,
        RadioGroupComponent,
        OtherListItemComponent,
        OtherCurrentSchoolComponent,
        SubTourListComponent,
        SelectSearchComponent,
        WidgetComponent,
        WidgetFilterComponent,
        MergeContactsComponent,
        MergeRelatedContactsComponent,
        MergeRelatedStudentsComponent,
        MergeStudentsComponent,
        ReviewRelatedStudentsComponent,
        ReviewRelatedContactsComponent,
        EditEmailTemplateComponent,
        ProspectusEmailTemplateComponent,
        GeneralEmailTemplateComponent,
        MergeListItemComponent,
        MergeCurrentSchoolComponent,
        MergeItemComponent,
        SetupFormHeaderFooterComponent,
        DisplayFormHeaderFooterComponent,
        StripeIntegrationComponent,
        StripeIntegrationModalComponent,
        ChartButtonGroupComponent,
        ChartActionSectionComponent,
        ChartwrapperComponent,
        ViewDetailsFooterComponent,
        ExportDialog,
        DownloadDialog,
        CopyPassword,
        PieZingChartComponent,
        ChartLegendComponent,
        TransferDialog,
        ColumnChartComponent,
        DuplicateNameDialog,
        LineAreaChartComponent,
        GoogleTranslateComponent,
        BulkEditStudentComponent,
        ChartMetaDataComponent,
        HeatMapChartComponent,
        EnquiryCountSnapshotsComponent,
        ProtectedInputComponent,
        AppEmailTemplateComponent,
        BulkEmailTemplateComponent,
        BulkSendEventEmailComponent,
        BulkSendAppEmailComponent,
        BulkSendStudentEmailComponent,
        TableStateInfoComponent,
        TableEndComponent,
        TableSpinnerComponent,
        StudentEnquirySwitcherComponent,
        RelatedContactsListComponent,
        EventEmailTemplateBaseDirective,
        EventEmailTemplateComponent,
        PTEmailTemplateComponent,
        ConditionalDropdownComponent,
    ],
    exports: [
        SpinnerModule,
        Angular2PromiseButtonModule,
        AddContactModalComponent,
        AddOrEditContactModalComponent,
        EditContactComponent,
        AddOrEditContactComponent,
        EditStudentComponent,
        EnquiryComponent,
        StudentProfileComponent,
        SelectContactModalComponent,
        SelectStudentModalComponent,
        PointViewComponent,
        StatisticPanelComponent,
        PieChartComponent,
        PieChartTableComponent,
        PieChartColComponent,
        LineChartComponent,
        TableComponent,
        FeedbackComponent,
        UserProfileComponent,
        BarChartComponent,
        PersonalTourWidgetComponent,
        ProspectusesWidgetComponent,
        GoogleMapComponent,
        BarChartGroupedComponent,
        BarChartColComponent,
        RelatedContactsComponent,
        RelatedContactsForNewStudentComponent,
        RelatedStudentsComponent,
        EditStudentModalComponent,
        BarChartStackedComponent,
        BarChartStackedHorizontalComponent,
        SchoolLogoComponent,
        EmailSignatureComponent,
        AddressPipe,
        DropDownComponent,
        FilterConstellationComponent,
        BFilterConstellationComponent,
        MoreOptionsComponent,
        TextBoxComponent,
        DateRangePickerComponent,
        FilterSwitchComponent,
        CheckBoxComponent,
        YearMonthPickerComponent,
        RadioButtonComponent,
        FunnelChartComponent,
        FunnelMetricsChartComponent,
        LineZingChartComponent,
        SendTestEmailComponent,
        GroupedTablePieChartComponent,
        EmailChipsComponent,
        CheckboxGroupComponent,
        RadioGroupComponent,
        OtherListItemComponent,
        OtherCurrentSchoolComponent,
        SubTourListComponent,
        SelectSearchComponent,
        WidgetComponent,
        WidgetFilterComponent,
        ProspectusEmailTemplateComponent,
        GeneralEmailTemplateComponent,
        MergeListItemComponent,
        MergeCurrentSchoolComponent,
        MergeItemComponent,
        SetupFormHeaderFooterComponent,
        DisplayFormHeaderFooterComponent,
        StripeIntegrationComponent,
        StripeIntegrationModalComponent,
        ChartButtonGroupComponent,
        ChartActionSectionComponent,
        ChartwrapperComponent,
        ViewDetailsFooterComponent,
        ExportDialog,
        DownloadDialog,
        CopyPassword,
        PieZingChartComponent,
        ChartLegendComponent,
        TransferDialog,
        ColumnChartComponent,
        DuplicateNameDialog,
        LineAreaChartComponent,
        GoogleTranslateComponent,
        ChartMetaDataComponent,
        HeatMapChartComponent,
        EnquiryCountSnapshotsComponent,
        ProtectedInputComponent,
        AppEmailTemplateComponent,
        BulkEmailTemplateComponent,
        BulkSendEventEmailComponent,
        BulkSendAppEmailComponent,
        BulkSendStudentEmailComponent,
        TableStateInfoComponent,
        TableEndComponent,
        TableSpinnerComponent,
        StudentEnquirySwitcherComponent,
        RelatedContactsListComponent,
        EventEmailTemplateComponent,
        PTEmailTemplateComponent,
        ConditionalDropdownComponent,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [
        { provide: NG_VALIDATORS, useExisting: emailValidator, multi: true },
        { provide: MAT_CHECKBOX_DEFAULT_OPTIONS, useValue: { color: 'primary' } as MatCheckboxDefaultOptions },
    ],
})
export class ComponentsModule {}
