import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ReactiveFormsModule, UntypedFormBuilder } from '@angular/forms';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Angular2PromiseButtonModule } from 'angular2-promise-buttons';
import { UserType } from 'app/common/enums';
import { OrgUser } from 'app/entities/user';
import { HttpService } from 'app/services/http.service';
import { SharedModule } from 'app/shared.module';

import { SpinnerModule } from '../spinner/spinner.module';
import { BaseUserDetailsModal } from './base-user-details-modal';
import { UserService } from './user.service';

@Component({
    selector: 'app-school-org-details',
    standalone: true,
    imports: [
        CommonModule,
        SpinnerModule,
        MatInputModule,
        MatCheckboxModule,
        ReactiveFormsModule,
        SharedModule,
        MatSlideToggleModule,
        MatSelectModule,
        Angular2PromiseButtonModule,
        MatTooltipModule,
    ],

    templateUrl: './user-details.component.html',
    providers: [UserService<OrgUser>],
})
export class OrgUserDetailsComponent extends BaseUserDetailsModal<OrgUser> {
    constructor(
        fb: UntypedFormBuilder,
        router: Router,
        httpService: HttpService,
        activeModal: NgbActiveModal,
        dialog: MatDialog,
        userService: UserService<OrgUser>
    ) {
        super(fb, router, httpService, activeModal, dialog, userService, UserType.OrgUser);
        this.isOrgAdminPage = true;
        this.shouldAllowSecondaryRole = true;
        this.secondaryRoleType = UserType.SchoolUser;
    }

    protected createUser(): OrgUser {
        return new OrgUser();
    }
}
