import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'timeUnitSuffix',
})
export class TimeUnitSuffixPipe implements PipeTransform {
    transform(timeUnit: string, timeValue: number): string {
        // slice plural 's' if value is 1
        return timeValue === 1 ? timeUnit.slice(0, -1) : timeUnit;
    }
}
