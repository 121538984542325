import { Injectable } from '@angular/core';
import { Utils } from 'app/common/utils';
import { CustomHttpParams } from 'app/entities/custom-http-params';
import { School } from 'app/entities/school';
import { UserInfo } from 'app/entities/userInfo';
import { HttpService } from 'app/services/http.service';

@Injectable({
    providedIn: 'root',
})
export class SchoolsService {
    constructor(private httpService: HttpService) {}

    async orgHasSchools(userInfo: UserInfo): Promise<boolean> {
        const params = new CustomHttpParams().generateFields(['id']);
        const orgSchools = await this.getOrgSchools(userInfo.organisationId, params);
        return orgSchools.length > 0;
    }

    getSchools(schoolsParams?: CustomHttpParams): Promise<School[]> {
        return this.httpService.getAuth<School[]>(`schools${schoolsParams ? '?' + Utils.toStringEncoded(schoolsParams) : ''}`);
    }

    getSchoolsList(): Promise<School[]> {
        return this.httpService.getAuth('schools/list');
    }

    getOrgSchools(orgId: number, params: CustomHttpParams): Promise<School[]> {
        return this.httpService.getAuth<School[]>(`orgs/${orgId}/schools?${Utils.toStringEncoded(params)}`);
    }

    assignSchoolsToOrg(organisationId: number | null, schoolIds: number[]): Promise<School[]> {
        const promises: Promise<School>[] = schoolIds.map(id => this.patchSchool(id, { organisationId }));
        return Promise.all(promises);
    }

    private patchSchool(id: number, school: Partial<School>): Promise<School> {
        return this.httpService.patchAuth(`schools/${id}`, school);
    }

    deleteSchool(id): Promise<void> {
        return this.httpService.deleteAuth(`schools/${id}`);
    }
}
