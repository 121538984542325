import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular';
import { Constants } from 'app/common/constants';
import { Utils } from 'app/common/utils';
import { User } from 'app/entities/user';
import { UserInfo } from 'app/entities/userInfo';
import { SchoolService } from 'app/state/school';
import * as _ from 'lodash';
import { NgxZendeskWebwidgetService } from 'ngx-zendesk-webwidget';

import { HttpService } from './http.service';

@Injectable({
    providedIn: 'root',
})
export class ZendeskService {
    private user: User = null;

    constructor(
        private httpService: HttpService,
        private ngxZendeskWebwidgetService: NgxZendeskWebwidgetService,
        private schoolService: SchoolService
    ) {}

    async hide() {
        await this.ngxZendeskWebwidgetService.initZendesk();
        this.ngxZendeskWebwidgetService.zE('webWidget', 'hide');
    }

    async showWidgetWithPrefillData() {
        await this.ngxZendeskWebwidgetService.initZendesk();
        if (!this.user) {
            await this.httpService
                .getAuth('users/get-current')
                .then((user: User) => {
                    this.user = user;
                })
                .catch(error => {
                    throw error;
                });
        }
        this.ngxZendeskWebwidgetService.zE('webWidget', 'prefill', {
            name: {
                value: `${this.user.lastName} ${this.user.firstName} - ${this.schoolService.getSchoolName()}`,
                readOnly: true,
            },
            email: {
                value: this.user.email,
                readOnly: true,
            },
        });
        this.ngxZendeskWebwidgetService.zE('webWidget', 'show');
        this.ngxZendeskWebwidgetService.zE.activate({ hideOnClose: true });
        Sentry.setUser({ id: this.user.id, name: `${this.user.lastName} ${this.user.firstName}`, email: this.user.email });
        Sentry.captureException(new Error(`Zendesk widget shown for school ${this.schoolService.getSchoolName()}`));
    }
}
