import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, TitleStrategy } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SchoolsService } from 'app/org/assigned-schools/schools.service';
import { AppStateService } from 'app/services/app-state.service';
import { OrgQuery } from 'app/state/organisation/org.query';
import { takeUntil } from 'rxjs/operators';

import { UserQuery } from '../../state/user/user.query';
import { BaseDashboardLayout } from '../base-dashboard-layout';
import { getOrgLayoutRoutes } from './org-layout.config';

@Component({
    selector: 'app-org-layout',
    templateUrl: './org-layout.component.html',
})
export class OrgLayoutComponent extends BaseDashboardLayout implements OnInit, OnDestroy {
    orgName$ = this.orgQuery.orgName$;
    orgId: number;
    shouldShowSwitcher: boolean;

    constructor(
        location: Location,
        router: Router,
        translateService: TranslateService,
        appStateService: AppStateService,
        userQuery: UserQuery,
        titleStrategy: TitleStrategy,
        private orgQuery: OrgQuery,
        private schoolsService: SchoolsService
    ) {
        super(location, router, translateService, appStateService, userQuery, titleStrategy);

        this.orgQuery.orgId$.pipe(takeUntil(this.destroy$)).subscribe(async id => {
            if (id) {
                this.orgId = id;
                await this.getLayoutMenu(this.userInfo.locale);
            }
        });
    }

    async ngOnInit() {
        await super.ngOnInit();
        await this.appStateService.updateOrgState(this.orgId);
        if (this.userInfo.isSysAdmin()) {
            this.shouldShowSwitcher = true;
        } else {
            //TODO: May cause bugs in the future when org_admin can assign schools
            this.shouldShowSwitcher = await this.schoolsService.orgHasSchools(this.userInfo);
        }
    }

    protected async getMenuItems() {
        return getOrgLayoutRoutes(this.userInfo, this.translations, this.orgId);
    }
}
