import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { Utils } from 'app/common/utils';
import { UserInfo } from 'app/entities/userInfo';
import { environment } from 'environments/environment';

export function createInitialState(): UserInfo {
    try {
        return Utils.getUserInfoFromToken();
    } catch {
        return new UserInfo(null, '', 'school', null, null, '', null, null, null, null, null, null, environment.localization.locale, false);
    }
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'user', resettable: true })
export class UserStore extends Store<UserInfo> {
    constructor() {
        super(createInitialState());
    }

    updateUser(): void {
        // afraid to not update correctly https://github.com/salesforce/akita/issues/661
        this._setState(createInitialState());
    }
}
