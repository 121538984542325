<mat-form-field *ngIf="!isMobileMenu() && organisations?.length">
    <mat-select
        placeholder="Selected {{ 'et.organisation' | translate }}"
        [value]="orgId"
        (selectionChange)="onSelectionChange($event.value)"
    >
        <mat-option *ngFor="let org of organisations" [value]="org.id">
            {{ org.name }}
        </mat-option>
    </mat-select>
</mat-form-field>

<ul *ngIf="isMobileMenu() && organisations?.length" class="nav">
    <li routerLinkActive="active" class="nav-item">
        <a data-bs-toggle="collapse" href="#orgSwitcher" class="nav-link">
            <p>{{ orgName$ | async }} <b class="caret"></b></p>
        </a>
        <div id="orgSwitcher" class="collapse">
            <ul class="nav">
                <li *ngFor="let org of organisations" routerLinkActive="active" class="nav-item">
                    <a (click)="onNavChange(org.id)" class="nav-link">{{ org.name }}</a>
                </li>
            </ul>
        </div>
    </li>
</ul>
