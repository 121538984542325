import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { DataService } from './data.service';

@Injectable({
    providedIn: 'root',
})
export class ListenerService {
    private schoolListUpdated = false;
    private schoolList = new Subject<any>();

    private schoolSelectSwitch = false;
    private schoolSelect = new Subject<any>();

    private campusListUpdated = false;
    private campusList = new Subject<any>();

    private campusSelectSwitch = false;
    private campusSelect = new Subject<any>();

    private eventListSwitch = false;
    private eventList = new Subject<any>();

    private sidebarWasToggled = false;
    private sidebarToggle = new Subject<any>();

    private capacityListSwitch = false;
    private capacityList = new Subject<any>();

    constructor(private dataService: DataService) {}

    public schoolListChanged() {
        this.dataService.resetAll();
        this.schoolListUpdated = !this.schoolListUpdated;
        this.schoolList.next(!this.schoolListUpdated);
    }

    public schoolListStatus(): Observable<any> {
        return this.schoolList.asObservable();
    }

    public schoolSelectChanged() {
        this.dataService.resetAll();
        this.schoolSelectSwitch = !this.schoolSelectSwitch;
        this.schoolSelect.next(!this.schoolSelectSwitch);
    }

    public schoolSelectStatus(): Observable<any> {
        return this.schoolSelect;
    }

    public campusListChanged() {
        this.dataService.resetAll();
        this.campusListUpdated = !this.campusListUpdated;
        this.campusList.next(!this.campusListUpdated);
    }

    public campusListStatus(): Observable<any> {
        return this.campusList;
    }

    public campusSelectChanged() {
        this.dataService.resetAll();
        this.campusSelectSwitch = !this.campusSelectSwitch;
        this.campusSelect.next(!this.campusListUpdated);
    }

    public campusSelectStatus(): Observable<any> {
        return this.campusSelect;
    }

    public eventListChanged() {
        this.eventListSwitch = !this.eventListSwitch;
        this.eventList.next(!this.eventListSwitch);
    }

    public eventListStatus(): Observable<any> {
        return this.eventList.asObservable();
    }

    public sidebarToggled() {
        this.sidebarWasToggled = !this.sidebarWasToggled;
        this.sidebarToggle.next(!this.sidebarWasToggled);
    }

    public sidebarStatus(): Observable<any> {
        return this.sidebarToggle.asObservable();
    }

    public capacityListChanged(): void {
        this.capacityListSwitch = !this.capacityListSwitch;
        this.capacityList.next(!this.capacityListSwitch);
    }

    public capacityListStatus(): Observable<any> {
        return this.capacityList.asObservable();
    }
}
