import { NgModule } from '@angular/core';

import { GetSubWidgetsPipe } from './get-sub-widgets.pipe';
import { IsWidgetVisiblePipe } from './is-widget-visible.pipe';

@NgModule({
    imports: [],
    declarations: [GetSubWidgetsPipe, IsWidgetVisiblePipe],
    exports: [GetSubWidgetsPipe, IsWidgetVisiblePipe],
})
export class WidgetPipeModule {}
