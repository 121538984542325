<nav #navbar class="navbar navbar-expand-lg navbar-transparent navbar-absolute">
    <div class="container-fluid gx-3">
        <div class="navbar-wrapper">
            <div class="navbar-minimize">
                <button mat-raised-button (click)="minimizeSidebar()" class="btn btn-just-icon btn-white btn-fab btn-round">
                    <i class="material-icons text_align-center visible-on-sidebar-regular">more_vert</i>
                    <i class="material-icons design_bullet-list-67 visible-on-sidebar-mini">view_list</i>
                </button>
            </div>
            <span *ngIf="pageInfo" class="navbar-brand">{{ pageInfo.title }}</span>
        </div>
        <button mat-button class="navbar-toggler btn-no-ripple" type="button" (click)="sidebarToggle()">
            <span class="visually-hidden">Toggle navigation</span>
            <span class="navbar-toggler-icon icon-bar"></span>
            <span class="navbar-toggler-icon icon-bar"></span>
            <span class="navbar-toggler-icon icon-bar"></span>
        </button>
        <div class="collapse navbar-collapse justify-content-end d-flex flex-wrap-reverse" id="navigation">
            <ng-content></ng-content>
            <ul class="navbar-nav">
                <li class="nav-item send">
                    <a class="nav-link" href="#" data-bs-toggle="collapse" (click)="userProfile()">
                        <i class="material-icons">account_box</i>
                        <p>
                            <span class="d-lg-none d-md-block">User Profile</span>
                        </p>
                    </a>
                </li>
                <ng-content select="[notifications]"></ng-content>
                <li class="nav-item send">
                    <a class="nav-link" href="#" data-bs-toggle="collapse" (click)="showZendeskWidget()">
                        <i class="material-icons">support</i> Help
                    </a>
                </li>
                <li class="nav-item logout">
                    <a class="nav-link" href="#" data-bs-toggle="collapse" (click)="logout()">
                        <i class="material-icons">power_settings_new</i> Logout
                    </a>
                </li>
            </ul>
        </div>
    </div>
</nav>
<app-feedback-cmp></app-feedback-cmp>
<app-user-profile-cmp></app-user-profile-cmp>
