import { Location } from '@angular/common';
import { Directive, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, NavigationStart, Router, TitleStrategy } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'app/common/interfaces/menu-item';
import { AppStateService } from 'app/services/app-state.service';
import { environment } from 'environments/environment';
import * as _ from 'lodash';
import { Subject, combineLatest, firstValueFrom, lastValueFrom, takeUntil } from 'rxjs';

import { Utils } from '../common/utils';
import { UserInfo } from '../entities/userInfo';
import { NavbarComponent } from '../shared/navbar/navbar.component';
import { UserQuery } from '../state/user/user.query';

export interface PageInfo {
    title: string;
    url: string;
}

@Directive()
export abstract class BaseDashboardLayout implements OnInit, OnDestroy {
    public menuItems: MenuItem[];
    public userInfo: UserInfo = null;
    public brand = environment.brand;
    public pageInfo: PageInfo;
    public translations: object;

    private lastPoppedUrl: string;
    private yScrollStack: number[] = [];

    protected destroy$ = new Subject<void>();

    @ViewChild(NavbarComponent) navbar: NavbarComponent;

    constructor(
        protected location: Location,
        protected router: Router,
        protected translateService: TranslateService,
        protected appStateService: AppStateService,
        protected userQuery: UserQuery,
        protected titleStrategy: TitleStrategy
    ) {
        this.userInfo = Utils.getUserInfoFromToken();
        this.userQuery.locale$.subscribe(async (locale: string) => {
            if (locale !== this.translateService.getDefaultLang()) {
                await this.getLayoutMenu(locale);
            }
        });
    }

    async ngOnInit() {
        this.userInfo = Utils.getUserInfoFromToken();
        await this.getLayoutMenu(this.userInfo.locale);
        combineLatest([this.userQuery.schoolId$, this.userQuery.orgId$])
            .pipe(takeUntil(this.destroy$))
            .subscribe(async () => {
                this.userInfo = Utils.getUserInfoFromToken();
                await this.getLayoutMenu(this.userInfo.locale);
            });
        const elemMainPanel = <HTMLElement>document.querySelector('.main-panel');
        this.router.events.pipe(takeUntil(this.destroy$)).subscribe(async (event: any) => {
            if (event instanceof NavigationStart) {
                if (event.url !== this.lastPoppedUrl) {
                    this.yScrollStack.push(window.scrollY);
                }
            } else if (event instanceof NavigationEnd) {
                this.pageInfo = await this.getPageInfo();
                elemMainPanel.scrollTop = 0;
                this.navbar.sidebarClose();
                if (event.url === this.lastPoppedUrl) {
                    this.lastPoppedUrl = undefined;
                    window.scrollTo(0, this.yScrollStack.pop());
                } else {
                    window.scrollTo(0, 0);
                }
            }
        });
    }

    isMobileMenu() {
        return Utils.isMobileSize();
    }

    async getLayoutMenu(locale: string) {
        if (locale !== this.translateService.getDefaultLang() || _.isEmpty(this.translations)) {
            this.translateService.use(locale);
            this.translateService.setDefaultLang(locale);
            this.translations = await lastValueFrom(this.translateService.getTranslation(locale));
        }
        this.menuItems = await this.getMenuItems();
        this.pageInfo = await this.getPageInfo();
    }

    public async getPageInfo(): Promise<PageInfo> {
        const pageTitle = await this.getPageTitle();
        if (pageTitle) {
            return { title: pageTitle, url: this.getPageUrl() };
        }
    }

    protected abstract getMenuItems(): Promise<MenuItem[]>;

    private async getPageTitle(): Promise<string | undefined> {
        const title = this.titleStrategy.buildTitle(this.router.routerState.snapshot);
        if (title) {
            // title must be defined to get translation
            // not all routes have titles
            // TODO discuss whether we want to have titles for all routes,
            // see https://enquirytracker.atlassian.net/browse/ET-7352.
            return await firstValueFrom(this.translateService.get(title));
        } else {
            return undefined;
        }
    }

    protected getPageUrl(): string {
        return this.location.prepareExternalUrl(this.location.path());
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
