import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';

import { TransferState, TransferStore } from './transfer.store';

@Injectable({ providedIn: 'root' })
export class TransferQuery extends QueryEntity<TransferState> {
    transfers$ = this.selectAll();

    constructor(protected store: TransferStore) {
        super(store);
    }
}
