import { Storage } from 'app/common/enums/storage';
import { Environment } from 'app/common/interfaces';

import { defaults } from './defaults.environment';
import { localization } from './locale.en-aus';

export const environment: Environment = {
    production: true,
    name: 'staging.enquirytracker.net',
    isAllowedInvalidAppSubmission: false,
    domainServer: 'staging-api.enquirytracker.net',
    apiUrl: 'https://staging-api.enquirytracker.net/',
    googleMapApiKey: 'AIzaSyBdRwoCnlPImVWvP1dRQlfDsj7xikYl9yo',
    googleTranslateApiKey: 'AIzaSyBdRwoCnlPImVWvP1dRQlfDsj7xikYl9yo',
    storageType: Storage.LocalStorage,
    reCaptchaSiteKey: '6LdaJKYZAAAAAMcwVptbIDgqk7y_H0yR4P4rBOp8',
    reCaptchaV2SiteKey: '6LeTAtIZAAAAAF1fv_k98sJhWJbCOzz6D5GQE4VR',
    ...defaults,
    localization,
};
