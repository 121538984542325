import { Injectable } from '@angular/core';
import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import { UserQuery } from 'app/state/user/user.query';
import { Subject, takeUntil } from 'rxjs';

@Injectable()
export class PaginatorService extends MatPaginatorIntl {
    itemsPerPageLabel = 'Items per page';
    nextPageLabel = 'Next page';
    previousPageLabel = 'Previous page';
    private locale: string;
    private unsubscribe = new Subject<void>();

    constructor(private userQuery: UserQuery) {
        super();
        this.userQuery.locale$.pipe(takeUntil(this.unsubscribe)).subscribe((i: string) => (this.locale = i));
    }

    getRangeLabel = (page: number, pageSize: number, length: number) => {
        if (length === 0 || pageSize === 0) {
            return `0 of ${length.toLocaleString(this.locale)}`;
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
        return `${(startIndex + 1).toLocaleString(this.locale)} - ${endIndex.toLocaleString(this.locale)} of ${length.toLocaleString(
            this.locale
        )}`;
    };

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
