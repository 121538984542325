import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from 'environments/environment';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'app-auth-layout',
    templateUrl: './auth-layout.component.html',
})
export class AuthLayoutComponent implements OnInit, OnDestroy {
    private toggleButton: any;
    private sidebarVisible: boolean;
    private _router: Subscription;
    loadNavbar = false;
    public brand = environment.brand;

    constructor(private router: Router, private element: ElementRef) {
        this.sidebarVisible = false;
        this._router = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
            this.loadNavbar = event && event.url && _.startsWith(_.lowerCase(event.url), 'no auth ') ? true : false;
        });
    }

    ngOnInit() {
        this.sidebarClose();
    }

    ngOnDestroy() {
        this._router.unsubscribe();
    }

    sidebarOpen() {
        this.toggleButton = this.element.nativeElement.getElementsByClassName('navbar-toggler')[0];
        const toggleButton = this.toggleButton;
        if (toggleButton) {
            const body = document.getElementsByTagName('body')[0];
            setTimeout(() => {
                toggleButton.classList.add('toggled');
            }, 500);
            body.classList.add('nav-open');

            this.sidebarVisible = true;
        }
    }

    sidebarClose() {
        if (this.loadNavbar) {
            const body = document.getElementsByTagName('body')[0];
            if (this.toggleButton) {
                this.toggleButton.classList.remove('toggled');
            }
            this.sidebarVisible = false;
            body.classList.remove('nav-open');
        }
    }

    sidebarToggle() {
        const body = document.getElementsByTagName('body')[0];
        if (this.sidebarVisible === false) {
            this.sidebarOpen();
            body.classList.add('nav-open');
        } else {
            this.sidebarClose();
        }
    }
}
