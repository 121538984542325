import { Injectable } from '@angular/core';
import { Constants } from 'app/common/constants';
import { TransferCheckRequestDTO } from 'app/common/dto/transfer';
import { TransferStatus } from 'app/common/enums';
import { Colors, Utils } from 'app/common/utils';
import { ManagementSystem } from 'app/entities/management-system';
import { HttpService } from 'app/services/http.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { SchoolQuery } from '../school';
import { TransferData } from './transfer.model';
import { TransferQuery } from './transfer.query';
import { TransferStore } from './transfer.store';

@Injectable({ providedIn: 'root' })
export class TransferStateService {
    private unsubscribe = new Subject<void>();
    private managementSystem: ManagementSystem;

    constructor(
        private transferStore: TransferStore,
        private transferQuery: TransferQuery,
        private httpService: HttpService,
        private schoolQuery: SchoolQuery
    ) {
        this.schoolQuery.managementSystem$
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((ms: ManagementSystem) => (this.managementSystem = ms));
    }

    add(transferData: TransferData | TransferData[]) {
        this.transferStore.add(transferData);
    }

    remove(ids: string | string[]) {
        this.transferStore.remove(ids);
    }

    markAsComplete(id: string, note?: string) {
        this.transferStore.update(id, { isComplete: true, note });
    }

    public checkTransfer(transferId: string): Promise<any> {
        return this.httpService.getAuth(`transfer/check-request/${transferId}`).then((checkTransferResult: TransferCheckRequestDTO) => {
            if (checkTransferResult.status === TransferStatus.Complete) {
                let note = '';
                if (checkTransferResult.listFailed.length === 0) {
                    note = 'All students transferred. Please check the email you received for detailed information.';
                    Utils.showSuccessNotification(
                        `Transferring students to ${this.managementSystem.name} finished successfully. All students were transferred. Please check the email you received for details.`
                    );
                } else if (checkTransferResult.listSucceeded.length === 0) {
                    note = 'Student transfer failed. Please check the email you received for detailed information.';
                    Utils.showNotification(
                        `Transferring students to ${this.managementSystem.name} finished with errors. No students were transferred. Please check the email you received for details.`,
                        Colors.danger
                    );
                } else if (checkTransferResult.listSucceeded.length !== 0 && checkTransferResult.listFailed.length !== 0) {
                    note = 'Not all students were transferred. Please check the email you received for detailed information.';
                    Utils.showNotification(
                        `Transferring students to ${this.managementSystem.name} finished. Note that ${checkTransferResult.listFailed.length} student(s) failed to transfer. Please check the email you received for details.`,
                        Colors.warning
                    );
                }
                this.markAsComplete(transferId, note);
            } else if (checkTransferResult.status === TransferStatus.InProgress || checkTransferResult.status === TransferStatus.Active) {
                setTimeout(() => {
                    this.checkTransfer(transferId);
                }, Constants.transferCheckPeriod);
            }
        });
    }

    subscribeToTransfer(userId) {
        this.transferQuery.transfers$.pipe(takeUntil(this.unsubscribe)).subscribe((transferData: TransferData[]) => {
            transferData.forEach((transfer: TransferData) => {
                if (transfer?.userId === userId && !transfer?.isComplete) {
                    this.checkTransfer(transfer.requestCode);
                }
            });
        });
    }

    unsubscribeFromTransfer() {
        if (this.unsubscribe) {
            this.unsubscribe.next();
            this.unsubscribe.complete();
        }
    }
}
