<div class="wrapper">
    <div class="sidebar" data-color="orange" data-background-color="black" [hidden]="userInfo.isSchoolRepresentative()">
        <app-sidebar-cmp [menuItems]="menuItems">
            <div id="org-school-admin-layout" class="user" *ngIf="userInfo.isSchoolAdminOrLower()">
                <div *ngIf="school$ | async as school">
                    <p class="simple-text">{{ school.name }}</p>
                </div>
            </div>

            <ng-container mobileSidebarContent *ngIf="isMobileMenu()">
                <li *ngIf="this.userInfo.isAuthorizedToSwitch()" class="nav-item">
                    <app-view-switcher></app-view-switcher>
                </li>

                <li *ngIf="userInfo.isAuthorizedToSwitch()" class="nav-item">
                    <app-school-switcher-cmp></app-school-switcher-cmp>
                </li>

                <li class="nav-item">
                    <app-campus-switcher-cmp></app-campus-switcher-cmp>
                </li>

                <li *ngIf="userInfo.isSchoolEditorOrHigher()" class="nav-item">
                    <app-notifications-cmp></app-notifications-cmp>
                </li>
            </ng-container>
        </app-sidebar-cmp>
        <div class="sidebar-background" style="background-image: url(assets/img/sidebar-1.jpg)"></div>
    </div>
    <div class="main-panel">
        <app-navbar-cmp [menuItems]="menuItems" [pageInfo]="pageInfo" [hidden]="userInfo.isSchoolRepresentative()">
            <ul class="navbar-nav gap-3 me-3" *ngIf="!isMobileMenu()">
                <li *ngIf="this.userInfo.isAuthorizedToSwitch()" class="nav-item">
                    <app-view-switcher></app-view-switcher>
                </li>

                <li *ngIf="userInfo.isAuthorizedToSwitch()" class="nav-item">
                    <app-school-switcher-cmp></app-school-switcher-cmp>
                </li>

                <li class="nav-item">
                    <app-campus-switcher-cmp></app-campus-switcher-cmp>
                </li>
            </ul>

            <li notifications class="nav-item" *ngIf="userInfo.isSchoolEditorOrHigher()">
                <app-notifications-cmp></app-notifications-cmp>
            </li>
        </app-navbar-cmp>
        <div class="main-content">
            <div class="container-fluid gx-3">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>
