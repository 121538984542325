import { School } from './school';

export type SchoolUserRoles = 'school_admin' | 'system_admin' | 'editor' | 'user' | 'school_representative' | 'contact';
export type OrgUserRoles = 'org_admin';
export type UserRoles = SchoolUserRoles | OrgUserRoles;
export type ETUser = User | OrgUser;
export type ViewMode = 'org' | 'school';

export abstract class AbstractUser<T extends UserRoles> {
    id: number;
    lastName: string;
    firstName: string;
    email: string;
    title: string;
    activated: boolean;
    organisationId?: number;
    role: T;
    roles: UserRoles[];
    viewMode: ViewMode;
    schoolId: number;
    campusId: number;
    school: School;
    lastLogin: string;
    last_shown_version: string;
    sendNotifications: boolean;
    isOAuthOnly: boolean;
}

export class OrgUser extends AbstractUser<OrgUserRoles> {
    public static ORG_ADMIN: OrgUserRoles = 'org_admin';
}

export class User extends AbstractUser<SchoolUserRoles> {
    public static ROLE_SYSADMIN: SchoolUserRoles = 'system_admin';
    public static ROLE_SCHOOLADMIN: SchoolUserRoles = 'school_admin';
    public static ROLE_EDITOR: SchoolUserRoles = 'editor';
    public static ROLE_USER: SchoolUserRoles = 'user';
    public static ROLE_SCHOOLREPRESENTATIVE: SchoolUserRoles = 'school_representative';
    public static ROLE_CONTACT: SchoolUserRoles = 'contact';
    // public static DEVELOPER = 'developer';
    // public static ROLES = DataType.ENUM(User.ROLE_SYSADMIN, User.ROLE_SCHOOLADMIN, User.ROLE_EDITOR, User.ROLE_USER);
}
