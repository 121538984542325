import { CommonModule, Location } from '@angular/common';
import { Component, ElementRef, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { PersistState } from '@datorama/akita';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'app/common/interfaces/menu-item';
import { ComponentsModule } from 'app/components/components.module';
import { PageInfo } from 'app/layouts/base-dashboard-layout';
import { ZendeskService } from 'app/services/zendesk.service';
import { OrgService } from 'app/state/organisation/org.service';
import { TransferStateService } from 'app/state/transfer/transfer-state.service';
import { UserStateService } from 'app/state/user/user-state.service';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { Constants } from '../../common/constants';
import { Utils } from '../../common/utils';
import { UserInfo } from '../../entities/userInfo';
import { DataService } from '../../services/data.service';
import { HttpService } from '../../services/http.service';
import { ListenerService } from '../../services/listener.service';
import { StateService } from '../../services/state.service';

declare const $: any;

const misc: any = {
    navbar_menu_visible: 0,
    active_collapse: true,
    disabled_collapse_init: 0,
};

@Component({
    selector: 'app-navbar-cmp',
    standalone: true,
    imports: [CommonModule, RouterModule, FormsModule, ReactiveFormsModule, ComponentsModule],
    templateUrl: 'navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnDestroy {
    @Input() menuItems: MenuItem[];
    @Input() pageInfo: PageInfo;

    userInfo: UserInfo = null;
    debugMode: boolean = Constants.debugMode;
    Constants = Constants;

    private toggleButton: any;
    private sidebarVisible: boolean;
    private ngUnsubScribe = new Subject<void>();

    @ViewChild('app-navbar-cmp') button: any;

    constructor(
        private location: Location,
        private element: ElementRef,
        private router: Router,
        private listenerService: ListenerService,
        private httpService: HttpService,
        private zendeskService: ZendeskService,
        private stateService: StateService,
        private translate: TranslateService,
        private dataService: DataService,
        private transferStateService: TransferStateService,
        private orgService: OrgService,
        private userStateService: UserStateService,
        @Inject('persistStorage') private persistStorage: PersistState
    ) {
        this.sidebarVisible = false;
        this.listenerService
            .schoolSelectStatus()
            .pipe(takeUntil(this.ngUnsubScribe))
            .subscribe(() => this.getNavBarTitles());
        this.listenerService
            .sidebarStatus()
            .pipe(takeUntil(this.ngUnsubScribe))
            .subscribe(() => {
                this.sidebarToggle();
            });
        this.translate.onLangChange.pipe(takeUntil(this.ngUnsubScribe)).subscribe((event: LangChangeEvent) => {
            this.getNavBarTitles();
        });
    }

    ngOnInit() {
        this.getNavBarTitles();

        const navbar: HTMLElement = this.element.nativeElement;
        const body = document.getElementsByTagName('body')[0];
        this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
        if (body.classList.contains('sidebar-mini')) {
            misc.sidebar_mini_active = true;
        }
        if (body.classList.contains('hide-sidebar')) {
            misc.hide_sidebar_active = true;
        }
        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .pipe(takeUntil(this.ngUnsubScribe))
            .subscribe(() => {
                this.sidebarClose();
            });
    }

    ngOnDestroy() {
        this.ngUnsubScribe.next();
        this.ngUnsubScribe.complete();
    }

    private getNavBarTitles() {
        this.userInfo = Utils.getUserInfoFromToken();
    }

    minimizeSidebar() {
        const body = document.getElementsByTagName('body')[0];

        if (misc.sidebar_mini_active === true) {
            body.classList.remove('sidebar-mini');
            misc.sidebar_mini_active = false;
        } else {
            setTimeout(() => {
                body.classList.add('sidebar-mini');
                misc.sidebar_mini_active = true;
            }, 300);
        }

        // we simulate the window Resize so the charts will get updated in realtime.
        const simulateWindowResize = setInterval(() => {
            window.dispatchEvent(new Event('resize'));
        }, 180);

        // we stop the simulation of Window Resize after the animations are completed
        setTimeout(() => {
            clearInterval(simulateWindowResize);
        }, 1000);
    }

    hideSidebar() {
        const body = document.getElementsByTagName('body')[0];
        const sidebar = document.getElementsByClassName('sidebar')[0];

        if (misc.hide_sidebar_active === true) {
            setTimeout(() => {
                body.classList.remove('hide-sidebar');
                misc.hide_sidebar_active = false;
            }, 300);
            setTimeout(() => {
                sidebar.classList.remove('animation');
            }, 600);
            sidebar.classList.add('animation');
        } else {
            setTimeout(() => {
                body.classList.add('hide-sidebar');
                // $('.sidebar').addClass('animation');
                misc.hide_sidebar_active = true;
            }, 300);
        }

        // we simulate the window Resize so the charts will get updated in realtime.
        const simulateWindowResize = setInterval(() => {
            window.dispatchEvent(new Event('resize'));
        }, 180);

        // we stop the simulation of Window Resize after the animations are completed
        setTimeout(() => {
            clearInterval(simulateWindowResize);
        }, 1000);
    }

    onResize() {
        return Utils.isMobileSize();
    }

    sidebarOpen() {
        const $toggle = document.getElementsByClassName('navbar-toggler')[0];
        const toggleButton = this.toggleButton;
        const body = document.getElementsByTagName('body')[0];
        setTimeout(function () {
            toggleButton.classList.add('toggled');
        }, 500);
        body.classList.add('nav-open');
        setTimeout(function () {
            $toggle.classList.add('toggled');
        }, 430);

        body.classList.add('nav-open');
        this.sidebarVisible = true;
    }

    sidebarClose() {
        const $toggle = document.getElementsByClassName('navbar-toggler')[0];
        const body = document.getElementsByTagName('body')[0];
        this.toggleButton.classList.remove('toggled');

        this.sidebarVisible = false;
        body.classList.remove('nav-open');

        setTimeout(() => {
            $toggle.classList.remove('toggled');
        }, 400);
    }

    sidebarToggle() {
        this.sidebarVisible === false ? this.sidebarOpen() : this.sidebarClose();
    }

    getPath(): string {
        return this.location.prepareExternalUrl(this.location.path());
    }

    isMobileMenu() {
        return Utils.isMobileSize();
    }

    userProfile() {
        $('#userProfileModal').modal('show');
    }

    showZendeskWidget() {
        this.zendeskService.showWidgetWithPrefillData();
    }

    logout() {
        console.log('logging out because clicked logout in the navbar');
        Utils.resetSession();
        localStorage.removeItem('token');
        this.transferStateService.unsubscribeFromTransfer();
        this.httpService.updateCurrentSchoolId(null);
        this.httpService.updateCurrentOrgId(null);
        this.stateService.resetFilter();
        this.dataService.resetAll();
        this.persistStorage.clearStore();
        this.zendeskService.hide();
        this.orgService.resetStore();
        this.userStateService.resetStore();
        this.router.navigate(['noAuth/login']);
    }
}
