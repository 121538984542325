<div class="logo d-flex flex-column justify-content-center">
    <a class="logo-mini" (click)="showAppInfo()">
        <img src="./assets/img/miniLogo.png" alt="{{ brand.name }}" />
    </a>
    <a class="logo-normal" (click)="showAppInfo()">
        <img src="./assets/img/Logo.png" alt="{{ brand.name }}" />
    </a>
</div>

<div class="sidebar-wrapper">
    <div class="user" *ngIf="userInfo.isSysAdmin() && debugMode">
        <div class="photo">
            <img src="./assets/img/faces/avatar.jpg" />
        </div>
        <div class="user-info">
            <a data-bs-toggle="collapse" href="#collapseExample" class="collapsed">
                <span>
                    Tania Andrew
                    <b class="caret"></b>
                </span>
            </a>
            <div class="collapse" id="collapseExample">
                <ul class="nav">
                    <li class="nav-item">
                        <a href="javascript:void(0)" class="nav-link">
                            <span class="sidebar-mini">MP</span>
                            <span class="sidebar-normal">My Profile</span>
                        </a>
                    </li>
                    <!-- <li class="nav-item">
                        <a href="javascript:void(0)" class="nav-link">
                            <span class="sidebar-mini">EP</span>
                            <span class="sidebar-normal">Edit Profile</span>
                        </a>
                    </li> -->
                    <li class="nav-item">
                        <a href="javascript:void(0)" class="nav-link">
                            <span class="sidebar-mini">S</span>
                            <span class="sidebar-normal">Settings</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <ng-content></ng-content>

    <ul class="nav">
        <li *ngFor="let menuItem of menuItems" class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <!--If is a single link-->
            <a [routerLink]="menuItem.path" *ngIf="menuItem.type === 'link' && !menuItem.hidden" class="nav-link">
                <i class="material-icons">{{ menuItem.icontype }}</i>
                <p>{{ menuItem.title }}</p>
            </a>
            <!--If it have a submenu-->
            <a
                data-bs-toggle="collapse"
                href="#{{ menuItem.collapse }}"
                *ngIf="menuItem.type === 'sub' && !menuItem.hidden"
                (click)="updateScrollbars()"
                class="nav-link"
            >
                <i class="material-icons">{{ menuItem.icontype }}</i>
                <p>
                    {{ menuItem.title }}
                    <b class="caret"></b>
                </p>
            </a>

            <!--Display the submenu items-->
            <div id="{{ menuItem.collapse }}" class="collapse" *ngIf="menuItem.type === 'sub' && !menuItem.hidden">
                <ul class="nav">
                    <ng-container *ngFor="let childitem of menuItem.children">
                        <li
                            *ngIf="!childitem.hidden"
                            routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: true }"
                            class="nav-item"
                        >
                            <a [routerLink]="menuItem.path + childitem.path" class="nav-link mt-1 py-1">
                                <i class="material-icons sub-nav-icon-m">{{ childitem.ab }}</i>
                                <span class="sidebar-normal">{{ childitem.title }}</span>
                            </a>
                        </li>
                    </ng-container>
                </ul>
            </div>
        </li>
        <li *ngIf="userInfo.isSysAdmin() && debugMode">
            <a href="http://md-pro-angular.creative-tim.com/documentation/tutorial?ref=md-pro-archive">
                <i class="material-icons">school</i>
                <p>Documentation</p>
            </a>
        </li>
    </ul>

    <div *ngIf="isMobileMenu()">
        <ul class="nav navbar-nav nav-mobile-menu">
            <li class="separator hidden-lg hidden-md"></li>

            <ng-content select="[mobileSidebarContent]"></ng-content>

            <li class="nav-item">
                <a (click)="userProfile()" class="dropdown-toggle" data-bs-toggle="dropdown" class="nav-link">
                    <i class="material-icons">account_box</i>
                    <p class="hidden-lg hidden-md">User Profile</p>
                </a>
            </li>

            <li class="send" class="nav-item">
                <a (click)="showZendeskWidget()" class="dropdown-toggle" data-bs-toggle="dropdown" class="nav-link">
                    <i class="material-icons">support</i>
                    <p class="hidden-lg hidden-md">Help</p>
                </a>
            </li>

            <li class="logout" class="nav-item">
                <a (click)="logout()" class="dropdown-toggle" data-bs-toggle="dropdown" class="nav-link">
                    <i class="material-icons">power_settings_new</i>
                    <p class="hidden-lg hidden-md">Logout</p>
                </a>
            </li>
            <li class="separator hidden-lg hidden-md"></li>
        </ul>
    </div>
</div>
