import { Constants } from 'app/common/constants';
import { EmailTemplateDTO } from 'app/common/dto/email-template';
import { EmailTemplateTypeId } from 'app/common/enums';
import { TimeUnitSuffixPipe } from 'app/common/pipes/timeunitsuffix.pipe';
import { Colors, Utils, createRange } from 'app/common/utils';
import { EmailTemplateSchedule } from 'app/entities/email-template-schedule';
import * as _ from 'lodash';
import * as moment from 'moment';
import { DurationInputArg2 } from 'moment-timezone';

export interface ScheduleState {
    typeId: EmailTemplateTypeId;
    isImmediate: boolean;
    scheduleTrigger: ScheduleMoment | null;
    scheduledLocalTime: string | null;
    timeNumberId: TimeNumberId | null;
    timeValue: number | null;
    timeUnit: TimeUnit | null;
    templateInfo: TemplateInfo | null;
}

export type ScheduleMoment = 'after' | 'prior' | 'after submission';

export enum TimeUnit {
    minutes = 'minutes',
    hours = 'hours',
    days = 'days',
    weeks = 'weeks',
}

export type Schedule = Pick<
    EmailTemplateDTO,
    'isImmediate' | 'scheduleMoment' | 'scheduledLocalTime' | 'isCustom' | 'timeValue' | 'timeUnit'
>;

export type EmailTrigger = { label: string; value: ScheduleMoment; postFix?: string; default?: true };

export type TimeSpan = { id: TimeSpanId; text: string; value: number; unit: TimeUnit | undefined };
export type TimeSpanId = '15m' | '30m' | '1h' | '2h' | '4h' | '8h' | '12h' | '1d' | '2d' | '3d' | '7d';
export type TimeNumberId = TimeSpanId | 'custom';
export const TimeSpans: TimeSpan[] = [
    { id: '15m', text: '15 minutes', value: 15, unit: TimeUnit.minutes },
    { id: '30m', text: '30 minutes', value: 30, unit: TimeUnit.minutes },
    { id: '1h', text: '1 hour', value: 1, unit: TimeUnit.hours },
    { id: '2h', text: '2 hours', value: 2, unit: TimeUnit.hours },
    { id: '4h', text: '4 hours', value: 4, unit: TimeUnit.hours },
    { id: '8h', text: '8 hours', value: 8, unit: TimeUnit.hours },
    { id: '12h', text: '12 hours', value: 12, unit: TimeUnit.hours },
    { id: '1d', text: '1 day', value: 1, unit: TimeUnit.days },
    { id: '2d', text: '2 days', value: 2, unit: TimeUnit.days },
    { id: '3d', text: '3 days', value: 3, unit: TimeUnit.days },
    { id: '7d', text: '7 days', value: 7, unit: TimeUnit.days },
];

export const isTimeUnitDayOrMore = (timeUnit: TimeUnit | null) => [TimeUnit.days, TimeUnit.weeks].includes(timeUnit);

export function getTimeSpan(id: TimeSpanId): TimeSpan {
    return TimeSpans.find(timeSpan => id === timeSpan.id);
}

export function findTimeSpanId(timeUnit: TimeUnit, value: number): TimeSpanId {
    const timeSpan = TimeSpans.find(timeSpan => timeUnit === timeSpan.unit && value === timeSpan.value);
    if (!timeSpan) throw new Error(`no timeSpan for unit:${timeUnit}, value: ${value}`);
    return timeSpan.id;
}

export type TemplateInfo = { immediate?: { label: string }; other: EmailTrigger[]; supportsMoreThanOneImmediateEmail: boolean };
const TemplateInfos = new Map<EmailTemplateTypeId, TemplateInfo>([
    [
        EmailTemplateTypeId.EventRegistrationForm,
        {
            immediate: { label: 'Immediately after Registration' },
            other: [
                { label: `prior to event`, value: 'prior', postFix: 'start time' },
                { label: `after event`, value: 'after', postFix: 'end time', default: true },
            ],
            supportsMoreThanOneImmediateEmail: true,
        },
    ],
    [
        EmailTemplateTypeId.Appointment,
        {
            immediate: { label: 'Immediately after Registration' },
            other: [
                { label: `prior to appointment`, value: 'prior', postFix: 'start time' },
                { label: `after appointment`, value: 'after', postFix: 'end time', default: true },
            ],
            supportsMoreThanOneImmediateEmail: true,
        },
    ],
    [
        EmailTemplateTypeId.GeneralEnquiry,
        {
            immediate: { label: 'On Submission' },
            other: [{ label: `after submission`, value: 'after submission', default: true }],
            supportsMoreThanOneImmediateEmail: false,
        },
    ],
    [
        EmailTemplateTypeId.InformationPack,
        {
            immediate: { label: 'On Submission' },
            other: [{ label: `after submission`, value: 'after submission', default: true }],
            supportsMoreThanOneImmediateEmail: false,
            // Han ET-5862: I also made this one false since the parent form email-template.component.html does not know the EmailTemplateTypeId,
            // but still needs to hide the delete button for the template using the more general EmailTemplateCategoryId (which this component does not know)
            // refactoring the code more make it more work/dangerous. Also it makes sense not to have more than 1 direct email.
        },
    ],
]);

export function getTemplateInfo(typeId: EmailTemplateTypeId): TemplateInfo {
    const templateInfo = TemplateInfos.get(typeId);
    if (templateInfo == null) {
        throw new Error(`There is no templateInfo for typeId: ${typeId} `);
    }
    return templateInfo;
}

export function getDisplayString(typeId: EmailTemplateTypeId, schedule: EmailTemplateSchedule, pipe: TimeUnitSuffixPipe): string {
    const templateInfo = getTemplateInfo(typeId);
    if (schedule.isImmediate) {
        return templateInfo.immediate.label;
    } else {
        const info = templateInfo.other.find(a => a.value === schedule.moment);
        if (!info) {
            Utils.showNotification(
                `Invalid scheduled moment ${schedule.moment} for ${schedule.id}. Please contact support.`,
                Colors.warning
            );
        }
        const postFix = info.postFix ? ` ${info.postFix}` : '';
        const localTime = isTimeUnitUsingLocalScheduling(schedule.timeUnit ?? defaultSchedule.timeUnit)
            ? ` at  ${getScheduledLocalTime(schedule.localTime, Constants.dateFormats.hourMinutes12h)}`
            : '';
        const timeUnitPluralSafe = pipe.transform(schedule.timeUnit, schedule.timeValue);
        return `${schedule.timeValue} ${timeUnitPluralSafe} ${info.label}${postFix}${localTime}`;
    }
}

export type TimeUnitInfo = { range: number[]; momentString: DurationInputArg2; usesLocalTimeScheduling: boolean; minutesPerValue: number };

const TimeUnitsInfo: Record<TimeUnit, TimeUnitInfo> = {
    [TimeUnit.minutes]: {
        range: createRange({ minValue: 1, maxValue: 120 }),
        momentString: 'm',
        usesLocalTimeScheduling: false,
        minutesPerValue: 1,
    },
    [TimeUnit.hours]: {
        range: createRange({ minValue: 1, maxValue: 48 }),
        momentString: 'h',
        usesLocalTimeScheduling: false,
        minutesPerValue: 60,
    },
    [TimeUnit.days]: {
        range: createRange({ minValue: 1, maxValue: 30 }),
        momentString: 'd',
        usesLocalTimeScheduling: true,
        minutesPerValue: 1440,
    },
    [TimeUnit.weeks]: {
        range: createRange({ minValue: 1, maxValue: 12 }),
        momentString: 'w',
        usesLocalTimeScheduling: true,
        minutesPerValue: 10080,
    },
};

export function convertToMinutes(value: number, unit: TimeUnit): number {
    return value * TimeUnitsInfo[unit].minutesPerValue;
}

export function getTimeUnitRange(unit: TimeUnit): number[] {
    return TimeUnitsInfo[unit].range;
}

export function isTimeUnitUsingLocalScheduling(unit: TimeUnit): boolean {
    return TimeUnitsInfo[unit].usesLocalTimeScheduling;
}

export const defaultSchedule: ScheduleState = {
    typeId: EmailTemplateTypeId.GeneralEnquiry,
    isImmediate: true,
    scheduleTrigger: null,
    scheduledLocalTime: '06:00',
    timeNumberId: '1d',
    timeValue: getTimeSpan('1d').value,
    timeUnit: getTimeSpan('1d').unit,
    templateInfo: null,
};

export function getScheduledLocalTime(
    scheduledLocalTime: string = defaultSchedule.scheduledLocalTime,
    format: string = Constants.dateFormats.hourMinutes
) {
    return moment().utcOffset(0).startOf('day').add(scheduledLocalTime).format(format);
}
