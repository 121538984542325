import { OrgUser, User, ViewMode } from './user';

export class UserInfo {
    constructor(
        public id: number,
        public role: string,
        public viewMode: ViewMode,
        public organisationId: number,
        public schoolId: number,
        public schoolUniqId: string,
        public campusId: number,
        public mainCampusId: number,
        public undecidedCampusId: number,
        public specificCampusId: number,
        public managementSystemId: number,
        public eventId: number,
        public locale: string,
        public canManageSysAdmins: boolean,
        public roles?: string[]
    ) {}

    public isOrgUser() {
        return this.isRole(OrgUser.ORG_ADMIN);
    }

    public isAbleToManageSysAdmins() {
        return this.isSysAdmin() && this.canManageSysAdmins;
    }

    public isAuthorizedToSwitch(): boolean {
        return this.isSysAdmin() || (this.isOrgAdmin() && this.hasSchoolRole());
    }

    public hasSchoolRole(): boolean {
        return this.isSchoolEditor() || this.isSchoolUser() || this.isSchoolAdmin();
    }

    public isSysAdmin() {
        return this.isRole(User.ROLE_SYSADMIN);
    }

    public isOrgAdmin() {
        return this.isRole(OrgUser.ORG_ADMIN);
    }

    public isSchoolAdmin() {
        return this.isRole(User.ROLE_SCHOOLADMIN);
    }

    public isSchoolRepresentative() {
        return this.isRole(User.ROLE_SCHOOLREPRESENTATIVE);
    }

    public isSchoolEditor() {
        return this.isRole(User.ROLE_EDITOR);
    }

    public isSchoolUser() {
        return this.isRole(User.ROLE_USER);
    }

    public isSchoolContact() {
        return this.isRole(User.ROLE_CONTACT);
    }

    public isSchoolUserOrHigher() {
        return this.isSchoolUser() || this.isSchoolEditorOrHigher();
    }

    public isSchoolEditorOrHigher() {
        return this.isSchoolEditor() || this.isSchoolAdminOrHigher();
    }

    public isSchoolAdminOrHigher() {
        return this.isSchoolAdmin() || this.isSysAdmin();
    }

    public isSchoolAdminOrLower() {
        return this.isSchoolAdmin() || this.isSchoolEditor() || this.isSchoolUser() || this.isSchoolRepresentative();
    }

    private isRole(role: string): boolean {
        if (this.roles) {
            return this.roles.includes(role);
        } else {
            return this.role === role;
        }
    }
}
