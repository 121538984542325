import { Injectable } from '@angular/core';
import { UserType } from 'app/common/enums';
import { Utils } from 'app/common/utils';
import { CustomHttpParams } from 'app/entities/custom-http-params';
import { EmailSignature } from 'app/entities/email-signature';
import { ETUser, User } from 'app/entities/user';
import { HttpService } from 'app/services/http.service';

@Injectable()
export class UserService<T extends ETUser> {
    constructor(private httpService: HttpService) {}

    deletable(userId: number, userType: UserType, handleError: boolean = true): Promise<boolean> {
        return this.httpService.postAuth<boolean>(`users/deletable/?type=${userType}`, { userId }, handleError);
    }

    addUser(user: User): Promise<T> {
        return this.httpService.postAuth<T>(`users/`, user);
    }

    updateUser(user: User): Promise<T> {
        return this.httpService.putAuth<T>(`users/${user.id}`, user);
    }

    getUsers(httpParams: CustomHttpParams): Promise<T[]> {
        return this.httpService.getAuth<T[]>(`users/with-attributes?${Utils.toStringEncoded(httpParams)}`);
    }

    getSysUsers(): Promise<T[]> {
        return this.httpService.getAuth<T[]>('users/system');
    }

    getUser(userId: number, type: string): Promise<T> {
        return this.httpService.getAuth<T>(`users/${userId}/?type=${type}`);
    }

    getRoles(type: UserType): Promise<string[]> {
        return this.httpService.getAuth<string[]>(`users/roles/?type=${type}`);
    }

    deleteUser(userId: number): Promise<void> {
        return this.httpService.deleteAuth(`users/${userId}`);
    }

    reassignUser(userId: number, reassignUserId: number): Promise<EmailSignature | undefined> {
        return this.httpService.postAuth('users/reassign-user', { userId, reassignUserId });
    }

    updateNotifications(user: User): Promise<void> {
        return this.httpService.postAuth('users/update-send-notifications', user);
    }
}
