<div class="wrapper">
    <div class="sidebar" data-color="orange" data-background-color="black" [hidden]="userInfo.isSchoolRepresentative()">
        <app-sidebar-cmp [menuItems]="menuItems">
            <div id="org-school-admin-layout" class="user" *ngIf="userInfo.isOrgUser()">
                <p class="simple-text">{{ orgName$ | async }}</p>
            </div>
            <ng-container mobileSidebarContent *ngIf="isMobileMenu()">
                <li *ngIf="userInfo.isAuthorizedToSwitch() && shouldShowSwitcher" class="nav-item">
                    <app-view-switcher></app-view-switcher>
                </li>

                <li *ngIf="userInfo.isSysAdmin()" class="nav-item">
                    <app-org-switcher></app-org-switcher>
                </li>
            </ng-container>
        </app-sidebar-cmp>
        <div class="sidebar-background" style="background-image: url(assets/img/sidebar-1.jpg)"></div>
    </div>
    <div class="main-panel">
        <app-navbar-cmp [menuItems]="menuItems" [pageInfo]="pageInfo" [hidden]="userInfo.isSchoolRepresentative()">
            <ul class="navbar-nav gap-3 me-3" *ngIf="!isMobileMenu()">
                <li *ngIf="userInfo.isAuthorizedToSwitch() && shouldShowSwitcher" class="nav-item">
                    <app-view-switcher></app-view-switcher>
                </li>

                <li *ngIf="userInfo.isSysAdmin()" class="nav-item">
                    <app-org-switcher></app-org-switcher>
                </li>
            </ul>
        </app-navbar-cmp>
        <div class="main-content">
            <div class="container-fluid gx-3">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>
