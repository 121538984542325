import { ActivatedRouteSnapshot, PRIMARY_OUTLET, RouterStateSnapshot } from '@angular/router';

/**
 * Based on https://github.com/angular/angular/blob/15.2.2/packages/router/src/page_title_strategy.ts#L46
 * @returns The deepest primary route.
 */
export function getLeafRoute(snapshot: RouterStateSnapshot): ActivatedRouteSnapshot {
    let route: ActivatedRouteSnapshot = snapshot.root;
    let leafRoute;
    while (route !== undefined) {
        leafRoute = route;
        route = route.children.find(child => child.outlet === PRIMARY_OUTLET);
    }
    return leafRoute;
}
