import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

import { Constants } from '../../common/constants';
import { ISelectUserData } from '../../common/interfaces';
import { HttpService } from '../../services/http.service';

@Component({
    selector: 'app-select-user-dialog',
    templateUrl: './select-user-dialog.component.html',
})
export class SelectUserDialogComponent {
    selectUserForm: UntypedFormGroup;
    loaded = false;
    public noItemSelected = Constants.noItemSelected; // show constant string in html

    public static getDialogConfig(data: ISelectUserData) {
        return {
            id: data.htmlId,
            width: '500px',
            minWidth: '200px',
            autoFocus: true,
            hasBackdrop: true,
            disableClose: true,
            data: data,
        };
    }

    constructor(
        private fb: UntypedFormBuilder,
        public dialogRef: MatDialogRef<SelectUserDialogComponent>,
        public httpService: HttpService,
        @Inject(MAT_DIALOG_DATA) public data: ISelectUserData
    ) {
        this.createForm();
    }

    private createForm() {
        this.selectUserForm = this.fb.group({
            userId: [null, Validators.compose([Validators.required])],
        });
        this.loaded = true;
    }

    public onSubmit() {
        this.dialogRef.close(this.selectUserForm.controls.userId.value);
        this.lastAction();
    }

    public onCancel() {
        this.dialogRef.close();
        this.lastAction();
    }

    private lastAction() {
        this.selectUserForm = null;
        this.loaded = false;
    }
}
