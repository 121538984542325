<form [formGroup]="profileFormGroup" *ngIf="templateData$ | async as data">
    <div class="row mt-3">
        <div class="col-12">
            <label class="mt-1"> When should this email be sent?</label>
            <br />
            <mat-radio-group class="d-grid" formControlName="isImmediate">
                <mat-radio-button
                    [value]="true"
                    [checked]="data.formValues.isImmediate"
                    [disabled]="data.setup.allowImmediateToggle === false && !data.formValues.isImmediate"
                >
                    {{ data.setup.templateInfo.immediate.label }}
                </mat-radio-button>
                <mat-radio-button
                    [value]="false"
                    [checked]="!data.formValues.isImmediate"
                    [disabled]="data.setup.allowImmediateToggle === false && data.formValues.isImmediate"
                >
                    On schedule (based on {{ campusTimezoneId$ | async }} timezone)
                </mat-radio-button>
            </mat-radio-group>
            <div class="row row-cols-5" *ngIf="!data.formValues.isImmediate">
                <div class="col-md-2">
                    <mat-form-field>
                        <mat-select formControlName="timeNumberUnit">
                            <mat-option *ngFor="let timeSpan of TimeSpans" [value]="timeSpan.id">
                                {{ timeSpan.text }}
                            </mat-option>
                            <mat-option [value]="'custom'"> Custom </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <ng-container *ngIf="data.formValues.timeNumberUnit === 'custom'">
                    <div class="col-md-1">
                        <mat-form-field>
                            <mat-select formControlName="timeValue">
                                <mat-option *ngFor="let timeValue of data.timeRange" [value]="timeValue">
                                    {{ timeValue }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-md-2">
                        <mat-form-field>
                            <mat-select formControlName="timeUnit">
                                <mat-option *ngFor="let timeUnit of timeUnits; let i = index" [value]="timeUnit">
                                    {{ timeUnit | timeUnitSuffix : data.formValues.timeValue }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </ng-container>
                <div class="col-md-4">
                    <ng-container *ngIf="data.setup.templateInfo.other.length > 1; else singleTrigger">
                        <mat-form-field>
                            <mat-select formControlName="scheduleMoment">
                                <mat-option *ngFor="let trigger of data.setup.templateInfo.other; let i = index" [value]="trigger.value">
                                    {{ trigger.label }} {{ trigger.postFix }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </ng-container>
                    <ng-template #singleTrigger>
                        <label class="pt-3"
                            >{{ data.setup.templateInfo.other[0].label }} {{ data.setup.templateInfo.other[0].postFix }}</label
                        >
                    </ng-template>
                </div>
                <div class="col-md-3" *ngIf="data.showTimePicker">
                    <div class="row">
                        <div class="col-md-2 pt-3"><label>at</label></div>
                        <div class="col-md-10">
                            <mat-form-field>
                                <input
                                    matInput
                                    formControlName="scheduledLocalTime"
                                    readonly
                                    [ngxMatTimepicker]="timePick"
                                    [format]="12"
                                    [disableClick]="true"
                                />
                                <ngx-mat-timepicker-toggle class="m-n2" matSuffix [for]="timePick">
                                    <mat-icon class="material-icons" ngxMatTimepickerToggleIcon> access_time </mat-icon>
                                </ngx-mat-timepicker-toggle>
                                <ngx-mat-timepicker #timePick> </ngx-mat-timepicker>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
