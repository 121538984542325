import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { Params, Router } from '@angular/router';
import { FileBlob } from 'app/common/interfaces/file-blob';
import { Subject, take, takeUntil } from 'rxjs';

import { Constants } from '../common/constants';
import { ErrorCode } from '../common/enums';
import { MediaType } from '../common/enums/media-type';
import { ResponseMessage } from '../common/interfaces';
import { Colors, Utils, getUserInfoFromTokenOptional } from '../common/utils';
import { UserInfo } from '../entities/userInfo';
import { ErrorMessageService } from './error-message.service';

@Injectable({
    providedIn: 'root',
})
export class HttpService implements OnDestroy {
    private static outdatedErrMsg = 'The contents in this tab are outdated. You will be redirected to the main page';

    public static httpHeaders = new HttpHeaders({
        'Content-Type': MediaType.json,
        Accept: MediaType.json,
    });

    private currentSchoolId: number = null;
    private currentOrgId: number = null;

    private static isUnauthorized(status: number): boolean {
        return status === 0 || status === 401 || status === 403;
    }

    private unsubscribe = new Subject<void>();
    selectedOrgIdFromToken$ = new Subject<number>();
    selectedSchoolIdFromToken$ = new Subject<number>();

    constructor(
        private http: HttpClient,
        private router: Router,
        private dateAdapter: DateAdapter<Date>,
        private errorMessageService: ErrorMessageService
    ) {
        this.selectedSchoolIdFromToken$.pipe(takeUntil(this.unsubscribe), take(1)).subscribe(() => {
            const path = Utils.getBaseUrl();
            this.notifyOutdatedContentAndReload(path);
        });

        this.selectedOrgIdFromToken$.pipe(takeUntil(this.unsubscribe), take(1)).subscribe((orgId: number) => {
            const basePath = Utils.getBaseUrl();
            const path = `${basePath}${Constants.baseOrgUrl}/${orgId}/summary`;
            this.notifyOutdatedContentAndReload(path);
        });
    }

    private checkSchoolOrOrgIdValid(): boolean {
        return this.router.url.startsWith(Constants.baseOrgUrl) ? this.checkOrgIdValid() : this.checkSchoolIdValid();
    }

    private notifyOutdatedContentAndReload(path: string) {
        Utils.showNotification(HttpService.outdatedErrMsg, Colors.warning);
        setTimeout(() => {
            window.location.href = path;
        }, 3000); // Wait 3 seconds for the notification, then reload the page
    }

    public updateCurrentSchoolId(schoolId: number) {
        this.currentSchoolId = schoolId;
    }

    public updateCurrentOrgId(organisationId: number) {
        this.currentOrgId = organisationId;
    }

    private checkSchoolIdValid() {
        const userInfo: UserInfo = Utils.getUserInfoFromToken();
        const schoolIdFromToken: number | undefined = userInfo?.schoolId;
        const isSchoolSame: boolean =
            schoolIdFromToken === this.currentSchoolId || this.currentSchoolId == null || schoolIdFromToken == null;
        if (!isSchoolSame) {
            this.selectedSchoolIdFromToken$.next(schoolIdFromToken);
        }
        return isSchoolSame;
    }

    private checkOrgIdValid() {
        const userInfo: UserInfo = Utils.getUserInfoFromToken();
        const orgIdFromToken: number | undefined = userInfo?.organisationId;
        const isOrgSame: boolean = orgIdFromToken === this.currentOrgId || this.currentOrgId == null || orgIdFromToken == null;
        if (!isOrgSame) {
            this.selectedOrgIdFromToken$.next(orgIdFromToken);
        }
        return isOrgSame;
    }

    get<T>(path: string, handleError = true): Promise<T> {
        return this.http
            .get<ResponseMessage>(Constants.noauthUrl + path, {
                observe: 'response',
                headers: new HttpHeaders({ Accept: MediaType.json }),
            })
            .toPromise()
            .then(res => {
                return this.handleError<T>(res.body, res.status, handleError);
            });
    }

    put(path: string, data: object, handleError = true): Promise<object> {
        return this.http
            .put<ResponseMessage>(Constants.noauthUrl + path, data, {
                observe: 'response',
                headers: HttpService.httpHeaders,
            })
            .toPromise()
            .then(res => {
                this.updateTokenIfExists(res.body);
                return this.handleError(res.body, res.status, handleError);
            });
    }

    post(path: string, data: object, handleError = true): Promise<object> {
        return this.http
            .post<ResponseMessage>(Constants.noauthUrl + path, data, {
                observe: 'response',
                headers: HttpService.httpHeaders,
            })
            .toPromise()
            .then(res => {
                this.updateTokenIfExists(res.body);
                return this.handleError(res.body, res.status, handleError);
            });
    }

    getAuth<T>(path: string, handleError = true): Promise<T> {
        if (this.checkSchoolOrOrgIdValid()) {
            return this.http
                .get<ResponseMessage>(Constants.authUrl + path, {
                    observe: 'response',
                    headers: new HttpHeaders({ Accept: MediaType.json }),
                })
                .toPromise()
                .then(res => {
                    this.updateTokenIfExists(res.body);
                    return this.handleError<T>(res.body, res.status, handleError);
                });
        } else {
            return Promise.reject(HttpService.outdatedErrMsg);
        }
    }

    async getAuthBlob(path: string, headers: HttpHeaders): Promise<FileBlob> {
        if (!this.checkSchoolOrOrgIdValid()) {
            return Promise.reject(HttpService.outdatedErrMsg);
        }
        const res = await this.http
            .get(Constants.authUrl + path, {
                observe: 'response',
                responseType: 'blob',
                headers,
            })
            .toPromise();

        if (res.body.type === MediaType.json) {
            const body = await res.body.text();
            const responseMessage: ResponseMessage = JSON.parse(body);
            return this.handleError(responseMessage, res.status, true);
        }
        const rawFilename =
            (res.headers.get('content-disposition') ?? '')
                .split(';')
                .map(h => h.trim())
                .find(h => h.startsWith('filename=')) || 'blob';
        const fileName = rawFilename.replace('filename=', '');
        return { fileBlob: res.body, fileName };
    }

    deleteAuth<T>(path: string, handleError = true): Promise<T> {
        if (this.checkSchoolOrOrgIdValid()) {
            return this.http
                .delete<ResponseMessage>(Constants.authUrl + path, { observe: 'response' })
                .toPromise()
                .then(res => {
                    this.updateTokenIfExists(res.body);
                    return this.handleError<T>(res.body, res.status, handleError);
                });
        } else {
            return Promise.reject(HttpService.outdatedErrMsg);
        }
    }

    postAuth<T>(path: string, data: object, handleError = true): Promise<T> {
        if (this.checkSchoolOrOrgIdValid()) {
            return this.http
                .post<ResponseMessage>(Constants.authUrl + path, data, {
                    observe: 'response',
                    headers: HttpService.httpHeaders,
                })
                .toPromise()
                .then(res => {
                    this.updateTokenIfExists(res.body);
                    return this.handleError<T>(res.body, res.status, handleError);
                });
        } else {
            return Promise.reject(HttpService.outdatedErrMsg);
        }
    }

    putAuth<T>(path: string, data: object, handleError = true): Promise<T> {
        if (this.checkSchoolOrOrgIdValid()) {
            return this.http
                .put<ResponseMessage>(Constants.authUrl + path, data, {
                    observe: 'response',
                    headers: HttpService.httpHeaders,
                })
                .toPromise()
                .then(res => {
                    this.updateTokenIfExists(res.body);
                    return this.handleError<T>(res.body, res.status, handleError);
                });
        } else {
            return Promise.reject(HttpService.outdatedErrMsg);
        }
    }

    patchAuth<T>(path: string, data: object, handleError = true): Promise<T> {
        if (this.checkSchoolOrOrgIdValid()) {
            return this.http
                .patch<ResponseMessage>(Constants.authUrl + path, data, {
                    observe: 'response',
                    headers: HttpService.httpHeaders,
                })
                .toPromise()
                .then(res => {
                    this.updateTokenIfExists(res.body);
                    return this.handleError<T>(res.body, res.status, handleError);
                });
        } else {
            return Promise.reject(HttpService.outdatedErrMsg);
        }
    }

    postAuthForm(path: string, formData: FormData, handleError = true) {
        if (this.checkSchoolOrOrgIdValid()) {
            return this.http
                .post<ResponseMessage>(Constants.authUrl + path, formData, { observe: 'response' })
                .toPromise()
                .then(res => {
                    return this.handleError(res.body, res.status, handleError);
                });
        } else {
            return Promise.reject(HttpService.outdatedErrMsg);
        }
    }

    postAuthImg(path: string, formData: FormData): Promise<object> {
        if (this.checkSchoolOrOrgIdValid()) {
            return this.http
                .post<ResponseMessage>(Constants.authUrl + path, formData, { observe: 'response' })
                .toPromise()
                .then(res => {
                    return this.handleError(res.body, res.status, true);
                });
        } else {
            return Promise.reject(HttpService.outdatedErrMsg);
        }
    }

    async postAuthBlob(path: string, data: object): Promise<Blob> {
        if (!this.checkSchoolOrOrgIdValid()) {
            return Promise.reject(HttpService.outdatedErrMsg);
        }
        const authData: HttpResponse<Blob> = await this.http
            .post(Constants.authUrl + path, data, {
                observe: 'response',
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Accept: 'application/json, application/zip, text/plain, */*',
                }),
                responseType: 'blob',
            })
            .toPromise();

        return new Promise<Blob>((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = async () => {
                try {
                    // if our blob does not contain zip file then throw an error
                    const msg = JSON.parse(reader.result as string);
                    if (msg.errorCode != null) {
                        const errMsg: string = await this.errorMessageService.getMessage(msg.errorCode, msg.errorMessage, msg?.params);
                        Utils.showNotification(errMsg, Colors.danger);
                        reject(msg);
                    }
                } catch (e) {
                    resolve(authData.body);
                    // ignore, we expect a blob
                }
            };
            const blob: Blob = authData.body;
            reader.readAsText(blob);
        });
    }

    private updateTokenIfExists(body: any) {
        if (body.token) {
            Utils.setToken(body.token);
            this.updateLocaleForUser();
        }
    }

    private updateLocaleForUser() {
        const userInfo = Utils.getUserInfoFromToken();
        if (userInfo?.locale) {
            this.dateAdapter.setLocale(userInfo.locale);
        }
    }

    private async handleError<T>(responseMessage: ResponseMessage, responseStatus: number, handleError: boolean): Promise<T> {
        if (HttpService.isUnauthorized(responseStatus)) {
            Utils.logout(this.router);
            return {} as T;
        }

        if (responseMessage.errorCode !== ErrorCode.no_error) {
            const errMsg: string = await this.errorMessageService.getMessage(
                responseMessage.errorCode,
                responseMessage.errorMessage,
                responseMessage?.params
            );
            if (handleError) Utils.showNotification(errMsg, Colors.danger);

            // For these error code(s), we want to redirect  to the start of request-application
            if (
                [ErrorCode.session_expired, ErrorCode.data_outdated].includes(responseMessage.errorCode) &&
                getUserInfoFromTokenOptional()?.isSchoolContact &&
                responseMessage.params?.length === 2
            ) {
                const [schoolUniqId, formId] = responseMessage.params;
                const loginUrl = `s/${schoolUniqId}/login`;
                const continueUrl = this.router.url;
                const entryId = continueUrl.split('/').pop();
                const queryParams: Params = { form_id: formId, entry_id: entryId, continue: continueUrl };

                Utils.logout(this.router, loginUrl, queryParams);
            }
            return Promise.reject(responseMessage);
        }

        return responseMessage.data as T;
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
