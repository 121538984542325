import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { HttpService } from './http.service';

type PromiseFunction<T extends Object = Object> = () => Promise<T>;

@Injectable({
    providedIn: 'root',
})
export class DataService {
    private callmap: Map<string, { resetOnPageChange: boolean; data: Promise<any> }> = new Map<
        string,
        { resetOnPageChange: boolean; data: Promise<any> }
    >();

    constructor(private httpService: HttpService, private router: Router) {
        this.router.events.subscribe((event: any) => {
            if (event instanceof NavigationEnd) {
                this.resetOnPageChange();
            }
        });
    }

    /**
     *
     * @param url
     * @param useCached
     * @param resetOnPageChange
     */
    public getAuth<T = Object>(url: string, useCached: boolean = true, resetOnPageChange: boolean = true): Promise<T> {
        const promiseFunction: PromiseFunction<T> = () => {
            return this.httpService.getAuth(url);
        };
        return this.cache<T>(url, useCached, resetOnPageChange, promiseFunction);
    }

    /**
     *
     * @param url
     * @param useCached
     * @param resetOnPageChange
     */
    public get(url: string, useCached: boolean = true, resetOnPageChange: boolean = true): Promise<object> {
        const promiseFunction: PromiseFunction = () => {
            return this.httpService.get(url);
        };
        return this.cache(url, useCached, resetOnPageChange, promiseFunction);
    }

    /**
     *
     * @param url
     * @param params
     * @param useCached
     * @param resetOnPageChange
     */
    public postAuth(url: string, params: any, useCached: boolean = true, resetOnPageChange: boolean = true): Promise<object> {
        const promiseFunction: PromiseFunction = () => {
            return this.httpService.postAuth(url, params);
        };
        return this.cache(url, useCached, resetOnPageChange, promiseFunction);
    }

    /**
     *
     * @param url
     * @param useCached
     * @param resetOnPageChange
     * @param promiseFunction
     */
    private cache<T = Object>(
        url: string,
        useCached: boolean = true,
        resetOnPageChange: boolean = true,
        promiseFunction: PromiseFunction<T>
    ): Promise<T> {
        if (!useCached || !this.callmap.get(url)) {
            const promise = promiseFunction();
            this.callmap.set(url, { resetOnPageChange, data: promise });
            return promise;
        } else {
            return this.callmap.get(url).data;
        }
    }

    public resetOne(url: string) {
        this.callmap.delete(url);
    }

    public resetAll() {
        this.callmap = new Map<string, { resetOnPageChange: boolean; data: Promise<object> }>();
    }

    private resetOnPageChange() {
        this.resetPageDependentData();
    }

    public resetPageDependentData() {
        this.callmap.forEach((element, key) => {
            if (element.resetOnPageChange) {
                this.callmap.delete(key);
            }
        });
    }
}
