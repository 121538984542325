export class VersionInfo {
    private major: number;
    private minor: number;
    private patch: number;

    constructor(versionString: string) {
        const versionParts = versionString.split('.');
        if (versionParts.length !== 3) {
            throw new Error('Invalid version string.');
        }
        this.major = parseInt(versionParts[0]);
        this.minor = parseInt(versionParts[1]);
        this.patch = parseInt(versionParts[2]);
    }

    isHigherVersion(other: VersionInfo): boolean {
        if (this.major !== other.major) {
            return this.major > other.major;
        }
        if (this.minor !== other.minor) {
            return this.minor > other.minor;
        }
        if (this.patch !== other.patch) {
            return this.patch > other.patch;
        }
        return false;
    }
}
