import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { Organisation } from 'app/entities/organisation';

import { OrgInfo } from './org.model';

export function createInitialState(): OrgInfo {
    return {
        id: null,
        name: '',
        timeZoneId: '',
        countryId: '',
    };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'organisation', resettable: true })
export class OrgStore extends Store<OrgInfo> {
    constructor() {
        super(createInitialState());
    }

    updateOrg(organisation: Organisation): void {
        this._setState({
            id: organisation.id,
            name: organisation.name,
            timeZoneId: organisation.timeZoneId,
            countryId: organisation.countryId,
        });
    }
}
