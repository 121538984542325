export const defaults = {
    brand: {
        name: 'Enquiry Tracker',
        companyName: 'Enquiry Tracker',
        title: 'Enquiry Tracker',
        supportEmail: 'support@enquirytracker.net',
        contactEmail: 'info@enquirytracker.net',
        privacyPolicyUrl: 'https://www.enquirytracker.net/privacypolicy/',
        termsOfUseUrl: 'https://www.enquirytracker.net/termsofuse/',
        homeUrl: 'https://enquirytracker.net/',
        whatsNewUrl: 'https://enquirytracker.zendesk.com/hc/en-us/categories/4407473846679-What-s-New',
        exportFilenamePrefix: 'EnquiryTracker',
        footerText: `© ${new Date().getFullYear()} Enquiry Tracker All rights reserved`,
        hideAppModuleIfNotEnabled: false,
        zendeskAccountUrl: 'enquirytracker.zendesk.com',
        supportGA4ArticleUrl: 'https://enquirytracker.zendesk.com/hc/en-us/articles/10739759331223-Setting-up-Google-Analytics-4',
        supportGA3ArticleUrl:
            'https://enquirytracker.zendesk.com/hc/en-us/articles/1500008522482-GA3-Setting-up-Google-Analytics-to-track-form-submissions',
    },
};
