import { CommonModule, PlatformLocation } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ModalAction } from 'app/common/enums';
import { ViewMode } from 'app/common/enums/view-mode';
import { AppInfoComponent } from 'app/components/app-info/app-info.component';
import { ZendeskService } from 'app/services/zendesk.service';
import { OrgQuery } from 'app/state/organisation/org.query';
import { OrgService } from 'app/state/organisation/org.service';
import { TransferStateService } from 'app/state/transfer/transfer-state.service';
import { UserStateService } from 'app/state/user/user-state.service';
import { environment } from 'environments/environment';
import PerfectScrollbar from 'perfect-scrollbar';
import { Subject, merge } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Constants } from '../common/constants';
import { Utils } from '../common/utils';
import { UserInfo } from '../entities/userInfo';
import { HttpService } from '../services/http.service';
import { ListenerService } from '../services/listener.service';

declare const $: any;

@Component({
    selector: 'app-sidebar-cmp',
    standalone: true,
    imports: [RouterModule, CommonModule, TranslateModule],
    templateUrl: 'sidebar.component.html',
    styleUrls: ['sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy {
    @Input() menuItems: any[];
    @Input() selectedView: ViewMode;

    debugMode: boolean = Constants.debugMode;
    ViewMode = ViewMode;
    userInfo: UserInfo = null;
    brand = environment.brand;

    private perfectScrollbarStatus = {
        on: 'perfect-scrollbar-on',
        off: 'perfect-scrollbar-off',
    };
    private perfectScrollbars: PerfectScrollbar[] = [];
    private ngUnsubScribe = new Subject<void>();

    constructor(
        private router: Router,
        private httpService: HttpService,
        private modalService: NgbModal,
        private platformLocation: PlatformLocation,
        private listenerService: ListenerService,
        private transferStateService: TransferStateService,
        private zendeskService: ZendeskService,
        private orgService: OrgService,
        private userStateService: UserStateService,
        private orgQuery: OrgQuery
    ) {
        merge(this.listenerService.schoolSelectStatus(), this.orgQuery.orgId$)
            .pipe(takeUntil(this.ngUnsubScribe))
            .subscribe(() => this.ngOnInit());
    }

    isMobileMenu() {
        return Utils.isMobileSize();
    }

    ngOnInit() {
        this.userInfo = Utils.getUserInfoFromToken();
        setTimeout(() => this.resetScrollbars());
    }

    private resetScrollbars(): void {
        this.destroyScrollbars();
        const html = document.getElementsByTagName('html')[0];
        html?.classList?.remove(this.perfectScrollbarStatus.on, this.perfectScrollbarStatus.off);
        if (window.matchMedia(`(min-width: 960px)`).matches && !Utils.isMac()) {
            const mainPanel = document.querySelector('.main-panel');
            const sidebarWrapper = document.querySelector('.sidebar .sidebar-wrapper');
            this.perfectScrollbars.push(new PerfectScrollbar(mainPanel));
            this.perfectScrollbars.push(new PerfectScrollbar(sidebarWrapper));
            html.classList.add(this.perfectScrollbarStatus.on);
            mainPanel.scrollTop = 0;
            sidebarWrapper.scrollTop = 0;
        } else {
            html.classList.add(this.perfectScrollbarStatus.off);
        }
    }

    public showAppInfo() {
        const appInfoModalRef = this.modalService.open(AppInfoComponent, Constants.ngbModalLg);
        this.platformLocation.onPopState(() => {
            appInfoModalRef.close({ action: ModalAction.LeavePage });
        });
    }

    updateScrollbars(): void {
        if (window.matchMedia(`(min-width: 960px)`).matches && !Utils.isMac()) {
            this.perfectScrollbars.forEach(ps => ps.update());
        }
    }

    private destroyScrollbars(): void {
        if (this.perfectScrollbars?.length) {
            this.perfectScrollbars.forEach(ps => {
                ps.destroy();
            });
            this.perfectScrollbars = [];
        }
    }

    ngOnDestroy() {
        this.ngUnsubScribe.next();
        this.ngUnsubScribe.complete();
        this.userInfo = null;
        this.destroyScrollbars();
    }

    userProfile() {
        $('#userProfileModal').modal('show');
        this.listenerService.sidebarToggled();
    }

    showZendeskWidget() {
        this.zendeskService.showWidgetWithPrefillData();
        this.listenerService.sidebarToggled();
    }

    logout() {
        console.log('logging out because clicked logout in the sidebar');
        localStorage.removeItem('token');
        this.transferStateService.unsubscribeFromTransfer();
        this.httpService.updateCurrentSchoolId(null);
        this.httpService.updateCurrentOrgId(null);
        this.zendeskService.hide();
        this.orgService.resetStore();
        this.userStateService.resetStore();
        this.router.navigate(['noAuth/login']);
    }
}
