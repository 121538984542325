import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { OrgService } from 'app/state/organisation/org.service';
import { TransferStateService } from 'app/state/transfer/transfer-state.service';
import { UserStateService } from 'app/state/user';

import { Utils } from '../common/utils';
import { HttpService } from './http.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild {
    constructor(
        private router: Router,
        private httpService: HttpService,
        private transferStateService: TransferStateService,
        public jwtHelper: JwtHelperService,
        private orgService: OrgService,
        private userStateService: UserStateService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const url: string = state.url;
        try {
            const expired: boolean = this.jwtHelper.isTokenExpired(localStorage.getItem('token'));
            if (!expired) {
                const userInfo = Utils.getUserInfoFromToken();
                if (url === '/') {
                    // log out contacts and school representative if they visit the main page
                    const notAllowedRole = userInfo?.isSchoolContact() || userInfo?.isSchoolRepresentative();
                    if (notAllowedRole) {
                        return this.logOut();
                    }
                    if (userInfo.viewMode === 'org') {
                        this.router.navigate([`orgs/${userInfo.organisationId}/summary`]);
                    } else {
                        this.router.navigate(['dashboard']);
                    }
                }
                return Utils.isAuthorized(userInfo, route.data.roles);
            }
            return this.logOut();
        } catch (error) {
            console.error(error);
            return this.logOut();
        }
    }

    private logOut() {
        this.httpService.updateCurrentSchoolId(null);
        this.httpService.updateCurrentOrgId(null);
        Utils.logout(this.router);
        this.userStateService.resetStore();
        this.transferStateService.unsubscribeFromTransfer();
        this.orgService.resetStore();
        return false;
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.canActivate(route, state);
    }
}
