import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular';
import { Subject } from 'rxjs';

import { HttpService } from './http.service';

@Injectable({
    providedIn: 'root',
})
export class ErrorService {
    public errorEvent: Subject<Error> = new Subject<any>();

    constructor(private httpService: HttpService) {}

    handleError(err: Error) {
        Sentry.captureException(err);
    }
}
