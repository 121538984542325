import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { PRIMARY_OUTLET, RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';
import { firstValueFrom } from 'rxjs';

export const PAGE_TITLE_SEPARATOR = new InjectionToken<string>('PageTitleSeparator');

@Injectable({ providedIn: 'root' })
export class PageHierarchyTitleStrategy extends TitleStrategy {
    constructor(
        private readonly title: Title,
        private translate: TranslateService,
        @Inject(PAGE_TITLE_SEPARATOR) private separator: string
    ) {
        super();
    }

    override async updateTitle(routerState: RouterStateSnapshot): Promise<void> {
        let route = routerState.root;
        let titles: string[] = [environment.brand.title];
        while (route !== undefined) {
            const title = this.getResolvedTitleForRoute(route);
            if (title) {
                const translatedTitle = await firstValueFrom(this.translate.get(title));
                titles = [translatedTitle, ...titles];
            }
            route = route.children.find(child => child.outlet === PRIMARY_OUTLET);
        }
        this.title.setTitle(titles.join(this.separator));
    }
}
