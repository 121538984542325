<form [formGroup]="selectSchoolForm" *ngIf="!isMobileMenu() && userInfo.isAuthorizedToSwitch() && schools?.length && selectSchoolForm">
    <app-select-search
        placeholder="Selected School"
        [list]="schools"
        [isRequired]="true"
        formControlName="schoolId"
        [showSubtitle]="true"
        (outputData)="schoolChanged($event.value)"
        [hideRequired]="true"
    >
    </app-select-search>
</form>

<div *ngIf="isMobileMenu()">
    <ul class="nav" *ngIf="userInfo.isAuthorizedToSwitch() && schools?.length">
        <li routerLinkActive="active" class="nav-item">
            <a data-bs-toggle="collapse" href="#schoolSelector2" class="nav-link">
                <!-- <i class="material-icons">notifications</i> -->
                <p>
                    {{ school.name }}
                    <b class="caret"></b>
                </p>
            </a>
            <div id="schoolSelector2" class="collapse">
                <ul class="nav">
                    <li *ngFor="let mySchool of schools" class="nav-item">
                        <a (click)="schoolChanged(mySchool.id)" class="nav-link">{{ mySchool.name }}</a>
                    </li>
                </ul>
            </div>
        </li>
    </ul>
</div>
