<mat-form-field *ngIf="!isMobileMenu() && this.userInfo.isAuthorizedToSwitch()" appearance="outline">
    <mat-label>View Mode</mat-label>
    <mat-select [value]="selectedOption" (selectionChange)="onSelectChange($event.value)">
        <mat-select-trigger class="option-with-icon">
            <mat-icon [fontIcon]="selectedOption.icon"></mat-icon> {{ selectedOption.title | translate }}
        </mat-select-trigger>
        <mat-option *ngFor="let viewOption of viewOptions" [value]="viewOption">
            <mat-icon [fontIcon]="viewOption.icon"></mat-icon>{{ viewOption.title | translate }}
        </mat-option>
    </mat-select>
</mat-form-field>

<ul *ngIf="isMobileMenu() && this.userInfo.isAuthorizedToSwitch()" class="nav">
    <li routerLinkActive="active" class="nav-item">
        <a data-bs-toggle="collapse" href="#viewSwitcher" class="nav-link border">
            <p><mat-icon [fontIcon]="selectedOption.icon"></mat-icon> {{ selectedOption.title | translate }} <b class="caret"></b></p>
        </a>
        <div id="viewSwitcher" class="collapse">
            <ul class="nav">
                <li routerLinkActive="active" class="nav-item" *ngFor="let viewOption of viewOptions">
                    <a [routerLink]="viewOption.path" class="nav-link" (click)="onNavChange()">
                        <mat-icon [fontIcon]="viewOption.icon"></mat-icon> {{ viewOption.title | translate }}
                    </a>
                </li>
            </ul>
        </div>
    </li>
</ul>
