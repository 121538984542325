<nav *ngIf="loadNavbar" class="navbar navbar-expand-lg bg-primary navbar-transparent navbar-absolute" color-on-scroll="500">
    <div class="container">
        <div class="navbar-wrapper">
            <a class="navbar-brand" [routerLink]="['/dashboard']">
                <div class="logo-container">
                    <div class="logo">
                        <img src="../../../assets/img/Logo.png" width="150px" alt="{{ brand.name }}" />
                    </div>
                </div>
            </a>
        </div>
        <button mat-button class="navbar-toggler" type="button" (click)="sidebarToggle()">
            <span class="visually-hidden">Toggle navigation</span>
            <span class="navbar-toggler-icon icon-bar"></span>
            <span class="navbar-toggler-icon icon-bar"></span>
            <span class="navbar-toggler-icon icon-bar"></span>
        </button>

        <div class="collapse navbar-collapse justify-content-end">
            <ul class="navbar-nav">
                <li class="nav-item" routerLinkActive="active">
                    <a class="nav-link" [routerLink]="['/noAuth/login']"> <i class="material-icons">fingerprint</i> Login </a>
                </li>
                <li class="nav-item" routerLinkActive="active">
                    <a class="nav-link" [routerLink]="['/noAuth/forgot-password']"> <i class="material-icons">lock</i> Forgot password </a>
                </li>
            </ul>
        </div>
    </div>
</nav>
<router-outlet></router-outlet>
