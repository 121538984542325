import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Constants } from 'app/common/constants';
import { Utils } from 'app/common/utils';
import { ViewOption } from 'app/components/view-switcher/view-switcher.component';
import { CustomHttpParams } from 'app/entities/custom-http-params';
import { Organisation } from 'app/entities/organisation';
import { UserInfo } from 'app/entities/userInfo';
import { SchoolsService } from 'app/org/assigned-schools/schools.service';
import { SchoolQuery } from 'app/state/school';
import { UserStateService } from 'app/state/user/user-state.service';
import * as _ from 'lodash';
import { Subject } from 'rxjs';

import { AppStateService } from './app-state.service';
import { HttpService } from './http.service';

@Injectable({
    providedIn: 'root',
})
export class OrganisationService implements OnDestroy {
    ngUnsubScribe = new Subject<void>();

    constructor(
        private router: Router,
        private schoolQuery: SchoolQuery,
        private httpService: HttpService,
        private appStateService: AppStateService,
        private userStateService: UserStateService,
        private schoolsService: SchoolsService
    ) {
        this.initialze();
    }

    async initialze() {
        const userInfo = Utils.getUserInfoFromToken();
        if (userInfo.organisationId) {
            await this.appStateService.updateOrgState();
        }
    }

    async switchViews(selectedOption: ViewOption): Promise<void> {
        const userInfo = Utils.getUserInfoFromToken();
        if (selectedOption.path.startsWith(Constants.baseOrgUrl)) {
            this.switchToOrganisation(userInfo);
        } else {
            await this.switchToSchool(userInfo);
            this.router.navigate(['/dashboard']);
        }
    }

    private async switchToOrganisation(userInfo: UserInfo): Promise<void> {
        const userOrganisationId = await this.setUserOrgId(userInfo.organisationId);
        this.router.navigate([`${Constants.baseOrgUrl}/${userOrganisationId}/summary`]);
    }

    private async setUserOrgId(claimOrgId: number): Promise<number> {
        let organisationId = claimOrgId;
        if (organisationId) {
            return organisationId;
        }
        const schoolOrgId = this.schoolQuery.getValue().organisationId;
        if (schoolOrgId) {
            organisationId = schoolOrgId;
        } else {
            organisationId = await this.fetchFirstOrganisation();
        }
        await this.userStateService.setOrg(organisationId);
        await this.appStateService.updateOrgState(organisationId);
        return organisationId;
    }

    private async fetchFirstOrganisation(): Promise<number> {
        const organisations = await this.fetchOrganisations();
        const firstOrg = organisations.shift();
        return firstOrg.id;
    }

    private async switchToSchool(userInfo: UserInfo): Promise<void> {
        if (!userInfo.schoolId) {
            const schoolId = await this.fetchFirstOrgSchool(userInfo.organisationId);
            await this.userStateService.setSchool(schoolId);
        }
    }

    private async fetchFirstOrgSchool(organisationId: number): Promise<number> {
        const params = new CustomHttpParams().generateFields(['id']);
        const schools = await this.schoolsService.getOrgSchools(organisationId, params);
        const firstSchool = schools.shift();
        return firstSchool.id;
    }

    private async fetchOrganisations(): Promise<Organisation[]> {
        const orgHttpParams = new CustomHttpParams()
            .generateFields(['id', 'name', 'timeZoneId'])
            .generateSort({ active: 'name', direction: 'asc' });

        return this.httpService.getAuth<Organisation[]>(`orgs?${Utils.toStringEncoded(orgHttpParams)}`);
    }

    async refreshOrgLayout(router: Router, pageRoute: any[], id: number): Promise<boolean> {
        await router.navigate([`${Constants.baseOrgUrl}/${id}/summary/sendback`], { replaceUrl: true });
        return router.navigate(pageRoute, { replaceUrl: true });
    }

    ngOnDestroy() {
        this.ngUnsubScribe.next();
        this.ngUnsubScribe.complete();
    }
}
