import { AfterViewInit, Directive, ElementRef } from '@angular/core';

import { Utils } from '../utils';

@Directive({
    selector: '[hide-autocomplete]',
})
export class HideAutoCompleteDirective implements AfterViewInit {
    constructor(private element: ElementRef) {}

    ngAfterViewInit(): void {
        const attributeName =
            this.element.nativeElement.getAttribute('formControlName') ??
            this.element.nativeElement.getAttribute('name') ??
            'undefined-name';
        const attributeNameWithRandomNumber = `${attributeName}-${Utils.getRandomNumber()}`;

        this.element.nativeElement.setAttribute('name', attributeNameWithRandomNumber);
        this.element.nativeElement.setAttribute('autocomplete', attributeNameWithRandomNumber);
    }
}
