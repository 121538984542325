import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Role } from 'app/common/enums';
import { Utils } from 'app/common/utils';
import { CustomHttpParams } from 'app/entities/custom-http-params';
import { Organisation } from 'app/entities/organisation';
import { OrgService } from 'app/org/org.service';
import { AppStateService } from 'app/services/app-state.service';
import { ListenerService } from 'app/services/listener.service';
import { OrganisationService } from 'app/services/organisation.service';
import { OrgQuery } from 'app/state/organisation/org.query';
import { UserStateService } from 'app/state/user';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'app-org-switcher',
    standalone: true,
    imports: [CommonModule, MatSelectModule, TranslateModule],
    providers: [OrgService],
    templateUrl: 'org-switcher.component.html',
    styleUrls: ['org-switcher.component.scss'],
})
export class OrgSwitcherComponent implements OnInit, OnDestroy {
    organisations: Partial<Organisation>[] = [];
    orgName$ = this.orgQuery.orgName$;
    orgId: number;

    private destroy$ = new Subject<void>();

    constructor(
        private router: Router,
        private orgService: OrgService,
        private listenerService: ListenerService,
        private organisationService: OrganisationService,
        private orgQuery: OrgQuery,
        private userStateService: UserStateService,
        private appStateService: AppStateService
    ) {}

    ngOnInit() {
        this.orgQuery.orgId$.pipe(takeUntil(this.destroy$)).subscribe(id => {
            this.orgId = id;
            const organisation = this.getOrgById(id);
            if (organisation?.timeZoneId) {
                this.setOrgTimeZone(organisation.timeZoneId);
            }
            const userInfo = Utils.getUserInfoFromToken();
            if (userInfo.role === Role.SystemAdmin) this.prepareOrgsList();
        });
    }

    private prepareOrgsList(): Promise<void> {
        const orgHttpParams: CustomHttpParams = new CustomHttpParams()
            .generateFields(['id', 'name', 'timeZoneId'])
            .generateSort({ active: 'name', direction: 'asc' });
        return this.orgService.getOrgs(orgHttpParams).then((orgs: Partial<Organisation>[]) => {
            this.organisations = orgs;
        });
    }

    isMobileMenu() {
        return Utils.isMobileSize();
    }

    async onSelectionChange(orgId: number): Promise<void> {
        await Promise.all([this.appStateService.updateOrgState(orgId), this.userStateService.setOrg(orgId)]);
        await this.organisationService.refreshOrgLayout(this.router, [`orgs/${orgId}/summary`], this.orgId);
    }

    onNavChange(orgId: number): void {
        this.onSelectionChange(orgId);
        this.listenerService.sidebarToggled();
    }

    private getOrgById(id?: number) {
        return id ? _.find(this.organisations, c => c.id === id) : _.head(this.organisations);
    }

    /**
     * Set default moment timezone by current campus timezone
     */
    private setOrgTimeZone(timeZoneId: string): void {
        moment.tz.setDefault(timeZoneId);
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
