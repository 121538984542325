import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { UserInfo } from 'app/entities/userInfo';

import { UserStore } from '.';

@Injectable({ providedIn: 'root' })
export class UserQuery extends Query<UserInfo> {
    locale$ = this.select(state => state.locale);
    orgId$ = this.select(state => state.organisationId);
    schoolId$ = this.select(state => state.schoolId);
    viewMode$ = this.select(state => state.viewMode);

    constructor(protected store: UserStore) {
        super(store);
    }
}
