import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { Router, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Constants } from 'app/common/constants';
import { Utils } from 'app/common/utils';
import { ViewMode } from 'app/entities/user';
import { UserInfo } from 'app/entities/userInfo';
import { ListenerService } from 'app/services/listener.service';
import { OrganisationService } from 'app/services/organisation.service';
import { UserStateService } from 'app/state/user/user-state.service';

export interface ViewOption {
    id: ViewMode;
    path: string;
    title: string;
    icon: string;
}

@Component({
    selector: 'app-view-switcher',
    standalone: true,
    imports: [CommonModule, RouterModule, FormsModule, MatSelectModule, MatIconModule, TranslateModule],
    templateUrl: './view-switcher.component.html',
    styleUrls: ['./view-switcher.component.scss'],
})
export class ViewSwitcherComponent {
    userInfo: UserInfo;
    selectedOption: ViewOption;
    private dashboardUrlPrefix = `/dashboard`;
    private schoolView: ViewOption = { id: 'school', path: this.dashboardUrlPrefix, title: 'School', icon: 'account_balance' };
    private orgView: ViewOption = { id: 'org', path: Constants.baseOrgUrl, title: 'et.organisation', icon: 'corporate_fare' };
    viewOptions: ViewOption[] = [this.schoolView, this.orgView];

    constructor(
        private router: Router,
        private listenerService: ListenerService,
        private organisationService: OrganisationService,
        private userStateService: UserStateService
    ) {
        this.userInfo = Utils.getUserInfoFromToken();
        this.selectedOption = this.router.url.startsWith(Constants.baseOrgUrl) ? this.orgView : this.schoolView;
    }

    async onSelectChange(selectedOption: ViewOption): Promise<void> {
        await this.organisationService.switchViews(selectedOption);
        this.userStateService.setViewMode(selectedOption.id);
    }

    onNavChange(): void {
        this.listenerService.sidebarToggled();
    }

    isMobileMenu() {
        return Utils.isMobileSize();
    }
}
