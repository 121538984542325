import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StripeStatus } from 'app/common/stripeStatus';
import { StripeService } from 'app/services/stripe.service';
import * as _ from 'lodash';
import { Subject, merge } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Constants } from '../../common/constants';
import { Colors, Utils } from '../../common/utils';
import { Event } from '../../entities/event';
import { SubTour } from '../../entities/sub-tour';
import { UserInfo } from '../../entities/userInfo';
import { HttpService } from '../../services/http.service';
import { ListenerService } from '../../services/listener.service';
import { LocaleService } from '../../services/locale.service';
import { TransferStateService } from '../../state/transfer/transfer-state.service';
import { TransferData } from '../../state/transfer/transfer.model';
import { TransferQuery } from '../../state/transfer/transfer.query';

@Component({
    selector: 'app-notifications-cmp',
    standalone: true,
    imports: [CommonModule],
    templateUrl: 'notifications.component.html',
})
export class NotificationsComponent implements OnInit, AfterViewInit, OnDestroy {
    userInfo: UserInfo = null;
    capacityEvents: Array<Event>;
    capacitySubTours: Array<SubTour>;
    unreadTransferNotes: TransferData[];
    stripeProblems: string[] = [];

    private unsubscribe = new Subject<void>();

    constructor(
        private httpService: HttpService,
        private router: Router,
        private listenerService: ListenerService,
        private localeService: LocaleService,
        private transferQuery: TransferQuery,
        private transferStateService: TransferStateService,
        private stripeService: StripeService
    ) {
        merge(
            this.listenerService.schoolSelectStatus(),
            this.listenerService.campusSelectStatus(),
            this.listenerService.capacityListStatus()
        )
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                this.getCapacityEvents();
                this.getCapacitySubTours();
            });
    }

    async ngAfterViewInit() {
        await this.getStripeStatus();
    }

    async ngOnInit() {
        this.userInfo = Utils.getUserInfoFromToken();
        this.subscribeToTransfer(this.userInfo.id);
        return Promise.all([this.getCapacityEvents(), this.getCapacitySubTours()]);
    }

    subscribeToTransfer(userId) {
        this.transferQuery.transfers$.pipe(takeUntil(this.unsubscribe)).subscribe((transfers: TransferData[]) => {
            this.unreadTransferNotes = _.filter(transfers, (t: TransferData) => t?.isComplete && t?.userId === userId);
        });
    }

    removeNote(id) {
        this.transferStateService.remove(id);
    }

    ngOnDestroy() {
        if (this.unsubscribe) {
            this.unsubscribe.next();
            this.unsubscribe.complete();
        }
    }

    private async getCapacityEvents() {
        this.capacityEvents = [];
        if (this.userInfo.isSchoolEditorOrHigher()) {
            const events: Event[] = await this.httpService.getAuth<Event[]>('events/capacity');
            _.forEach(events, (event: Event) => {
                const date = this.localeService.transformLocaleDate(event.date, Constants.localeFormats.dateDelimiter);
                if (event.isFull) {
                    event.alert = `Your ${event['schoolTour.name']} on ${date} is at capacity`;
                    this.capacityEvents.push(event);
                } else if (event.isAlmostFull && !event.isFull) {
                    event.alert = `Your ${event['schoolTour.name']} on ${date} is almost full`;
                    this.capacityEvents.push(event);
                }
            });
        }
    }

    private async getCapacitySubTours() {
        this.capacitySubTours = [];
        if (this.userInfo.isSchoolEditorOrHigher()) {
            const subTours = await this.httpService.getAuth<SubTour[]>('events/null/sub-tours/capacity');
            _.forEach(subTours, (st: SubTour) => {
                if (st.isFull) {
                    st.alert = `Your ${st.event.schoolTour.name}: ${st.name} on
                    ${this.localeService.transformLocaleDate(st.event.date, Constants.localeFormats.dateDelimiter)}
                    is at capacity`;
                    this.capacitySubTours.push(st);
                }
            });
        }
    }

    private async getStripeStatus() {
        if (this.userInfo.isSchoolEditorOrHigher()) {
            this.stripeService.currentStatus.pipe(takeUntil(this.unsubscribe)).subscribe(async status => {
                switch (status) {
                    case StripeStatus.WEB_HOOK_DISABLED:
                        this.stripeProblems.push(Constants.stripeWebhookNotEnabledMessage);
                        Utils.showNotification(Constants.stripeWebhookNotEnabledMessage, Colors.warning);
                        break;
                    case StripeStatus.WEB_HOOK_UNEXPECTED_FAIL:
                        this.stripeProblems.push(Constants.stripeWebhookSetupFailMessage);
                        Utils.showNotification(Constants.stripeWebhookNotEnabledMessage, Colors.warning);
                        break;
                    default:
                }
            });
            this.stripeProblems = [];
            await this.stripeService.notifyStripeWebhookStatus();
        }
    }

    isMobileMenu() {
        return Utils.isMobileSize();
    }

    editEvent(event: any) {
        this.router.navigate(['dashboard/sendback']).then(() => {
            this.router.navigate([Constants.editEventUrl, { eventId: event.id, campusId: event.campusId }]);
            _.remove(this.capacityEvents, (item: any) => item.id === event.id);
        });
    }

    editSubTourEvent(subTour: SubTour) {
        this.router.navigate(['dashboard/sendback']).then(() => {
            this.router.navigate([Constants.editEventUrl, { eventId: subTour.eventId }]);
            _.remove(this.capacitySubTours, (item: SubTour) => item.id === subTour.id);
        });
    }
}
