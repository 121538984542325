import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';

import { OrgInfo } from './org.model';
import { OrgStore } from './org.store';

@Injectable({ providedIn: 'root' })
export class OrgQuery extends Query<OrgInfo> {
    organisation$ = this.select(state => state);
    orgId$ = this.select(state => state.id);
    orgName$ = this.select(state => state.name);
    timeZone$ = this.select(state => state.timeZoneId);
    countryId$ = this.select(state => state.countryId);

    constructor(protected store: OrgStore) {
        super(store);
    }
}
