import { Injectable, OnDestroy } from '@angular/core';
import { Organisation } from 'app/entities/organisation';
import { OrgService } from 'app/state/organisation/org.service';
import { Subject, takeUntil } from 'rxjs';

import { Utils } from '../common/utils';
import { School } from '../entities/school';
import { UserInfo } from '../entities/userInfo';
import { SchoolService } from '../state/school/school.service';
import { HttpService } from './http.service';
import { ListenerService } from './listener.service';

@Injectable({ providedIn: 'root' })
export class AppStateService implements OnDestroy {
    private destroy$ = new Subject<void>();

    constructor(
        private listenerService: ListenerService,
        private schoolService: SchoolService,
        private httpService: HttpService,
        private orgService: OrgService
    ) {
        this.listenerService
            .schoolSelectStatus()
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => this.updateStateSchool());
        this.listenerService
            .campusSelectStatus()
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => this.updateStateSchool());
    }

    public async updateStateSchool(): Promise<void> {
        const userInfo: UserInfo = Utils.getUserInfoFromToken();
        if (userInfo.schoolId) {
            const school: School = await this.httpService.getAuth('schools/get/' + userInfo.schoolId);
            this.schoolService.set(school);
        }
    }

    public async updateOrgState(orgId?: number): Promise<void> {
        const organisationId = orgId || Utils.getUserInfoFromToken().organisationId;

        if (organisationId) {
            const organisation: Organisation = await this.httpService.getAuth(`orgs/${organisationId}`);
            this.orgService.set(organisation);
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
