import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { TransferData } from './transfer.model';

export type TransferState = EntityState<TransferData, string>;

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'transfer', idKey: 'requestCode' })
export class TransferStore extends EntityStore<TransferState> {
    constructor() {
        super();
    }
}
