import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

import { School } from '../../entities/school';
import { SchoolInfo } from './school.model';

export function createInitialState(): SchoolInfo {
    return {
        id: null,
        name: '',
        organisationId: null,
        startingMonth: 0,
        isBoardingEnabled: null,
        hasInternationals: null,
        isReligionDisabled: null,
        timeZoneId: '',
        modules: [],
        exportType: null,
        managementSystem: { id: null, name: '', export2Xml: false, format: 'default', supportedExportType: null },
        isGoogleTranslateEnabled: false,
        googleTrackingIsEnabled: false,
        currentSchoolDisplayOther: false,
        campuses: [],
        isStudentExternalIdEditable: false,
        countryId: '',
    };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'school' })
export class SchoolStore extends Store<SchoolInfo> {
    constructor() {
        super(createInitialState());
    }

    updateSchool(school: School): void {
        this._setState({
            id: school.id,
            name: school.name,
            organisationId: school.organisationId,
            startingMonth: school.startingMonth,
            timeZoneId: school.timeZoneId,
            modules: school.modules,
            managementSystem: school.managementSystem,
            exportType: school.exportType,
            hasInternationals: school.hasInternationals,
            isReligionDisabled: school.isReligionDisabled,
            isBoardingEnabled: school.isBoardingEnabled,
            isGoogleTranslateEnabled: school.isGoogleTranslateEnabled,
            googleTrackingIsEnabled: school.googleTrackingIsEnabled,
            currentSchoolDisplayOther: school.currentSchoolDisplayOther,
            campuses: school.campuses,
            isStudentExternalIdEditable: school.isStudentExternalIdEditable,
            countryId: school.countryId,
        });
    }
}
