<form [formGroup]="searchSelectForm" novalidate>
    <mat-form-field [hideRequiredMarker]="hideRequired">
        <mat-label [attr.translate]="translateText" for="{{ placeholder }}">{{ placeholder }}</mat-label>
        <mat-select
            panelClass="select-search-options"
            disableOptionCentering
            formControlName="val"
            [required]="isRequired"
            (selectionChange)="onChange($event.value)"
        >
            <mat-select-trigger *ngIf="selectedItem">
                <span *ngIf="hasAlternativeName && selectedItem.alternativeName; else itemName" [attr.translate]="translateText">
                    {{ selectedItem.alternativeName }}
                </span>
                <ng-template #itemName>
                    <span [attr.translate]="translateText">{{ selectedItem.name }}</span>
                </ng-template>
            </mat-select-trigger>
            <mat-option>
                <ngx-mat-select-search
                    [formControl]="listFilterCtrl"
                    placeholderLabel="Search"
                    noEntriesFoundLabel="No matching item found"
                ></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngIf="!isRequired" [value]="null">
                <span [attr.translate]="translateText">{{ noItemSelected }}</span>
            </mat-option>
            <mat-option *ngFor="let item of filteredList | async" [value]="item.id">
                <span [attr.translate]="translateText">{{ item.name }}</span>

                <div class="subtitle" *ngIf="showSubtitle && item.subtitle">
                    <span [attr.translate]="translateText">{{ item.subtitle }}</span>
                </div>
            </mat-option>
        </mat-select>
    </mat-form-field>
</form>
