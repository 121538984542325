import { Injectable, OnDestroy } from '@angular/core';
import { Constants } from 'app/common/constants';
import { StripeKeyPairDTO } from 'app/common/dto/stripe-key-pair';
import { StripeStatus } from 'app/common/stripeStatus';
import { Observable, Subject, takeUntil } from 'rxjs';

import { UserInfo } from '../entities/userInfo';
import { HttpService } from './http.service';
import { ListenerService } from './listener.service';

@Injectable({
    providedIn: 'root',
})
export class StripeService implements OnDestroy {
    private unsubscribe = new Subject<void>();
    private _currentStatus = new Subject<StripeStatus>();

    constructor(private httpService: HttpService, private listenerService: ListenerService) {
        // When school is changed, webhook being enabled needs to be checked
        this.listenerService
            .schoolSelectStatus()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                this.notifyStripeWebhookStatus();
            });
    }
    /**
     * Returns a stripe status. As of now, 6 statuses exist
     */
    public async getStripeStatus(): Promise<StripeStatus> {
        return this.httpService.getAuth<StripeStatus>('payment/get-stripe-status').then((status: StripeStatus) => {
            return status;
        });
    }
    /**
     *  Notifies webhook status to caller.
     * @returns void
     */
    public async notifyStripeWebhookStatus(): Promise<void> {
        const status = await this.getStripeStatus();
        this.notifyStatus(status);
    }

    public getStripeForSchool(userInfo: UserInfo): Promise<StripeKeyPairDTO> {
        return this.httpService.getAuth('schools/stripe-key-pair').then((data: StripeKeyPairDTO) => {
            return data;
        });
    }

    get currentStatus(): Observable<StripeStatus> {
        return this._currentStatus.asObservable();
    }

    public notifyStatus(status: StripeStatus): void {
        this._currentStatus.next(status);
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
