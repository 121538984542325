<div class="card">
    <div class="card-header card-header-icon card-header-primary">
        <div class="card-icon">
            <i class="material-icons-outlined">{{ icon }}</i>
        </div>
        <h4 class="card-title">{{ cardTitle }} Users</h4>
    </div>
    <div class="card-body">
        <div class="text-end">
            <a id="add-school-user" mat-raised-button class="btn btn-primary" (click)="addUser()">
                <i class="material-icons">playlist_add</i>Add {{ cardTitle }} User
            </a>
        </div>
        <div class="row">
            <div class="col-sm-8"></div>
            <div class="col-sm-4">
                <div class="float-end mx-2">
                    <mat-form-field>
                        <input
                            #search
                            matInput
                            (keyup)="applyFilter(search.value)"
                            [value]="tableState.searchText"
                            placeholder="Search results"
                            name="school-user-search"
                            autocomplete="school-user-search"
                        />
                    </mat-form-field>
                </div>
            </div>
        </div>

        <div class="table-responsive">
            <table [id]="tableId" mat-table [dataSource]="dataSource" matSort (matSortChange)="onSortChange($event)">
                <!-- Name Column -->
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
                    <td mat-cell *matCellDef="let element">{{ element.name }}</td>
                </ng-container>

                <!-- Email Column -->
                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
                    <td mat-cell *matCellDef="let element">{{ element.email }}</td>
                </ng-container>

                <!-- Status Column -->
                <ng-container matColumnDef="activated">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.activated ? 'Enabled' : 'Disabled' }}
                    </td>
                </ng-container>

                <!-- Access Level Column -->
                <ng-container matColumnDef="role">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Access Level</th>
                    <td mat-cell *matCellDef="let element">
                        <ng-container *ngIf="element.roles; else role">
                            <span class="role" *ngFor="let role of element.roles">
                                <ng-container>{{ role }}</ng-container>
                            </span>
                        </ng-container>
                        <ng-template #role>
                            {{ element.role }}
                        </ng-template>
                    </td>
                </ng-container>

                <!-- Notifications Column -->
                <ng-container matColumnDef="sendNotifications">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Notifications</th>
                    <td mat-cell *matCellDef="let element">
                        <div
                            matTooltip="{{ !element.activated ? 'Enable this user to turn on notifications' : '' }}"
                            matTooltipPosition="left"
                        >
                            <mat-checkbox
                                [disabled]="!element.activated"
                                (change)="updateSendNotifications($event.checked, element.id)"
                                [checked]="element.sendNotifications"
                            >
                            </mat-checkbox>
                        </div>
                    </td>
                </ng-container>

                <!-- LastLogin Column -->
                <ng-container matColumnDef="lastLogin">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Login</th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.lastLogin | localeDate : dateDelimiterTime }}
                    </td>
                </ng-container>

                <!-- Actions Column -->
                <ng-container matColumnDef="actions">
                    <th class="text-center" mat-header-cell *matHeaderCellDef>Actions</th>
                    <td class="td-actions text-center" mat-cell *matCellDef="let element">
                        <div
                            class="d-inline"
                            matTooltip="{{ element.activated ? 'Resend activation email' : 'Enable this user to send activation email' }}"
                            matTooltipPosition="left"
                        >
                            <button
                                mat-raised-button
                                type="button"
                                class="btn btn-primary btn-link"
                                (click)="inviteUser(element.email)"
                                [disabled]="!element.activated"
                            >
                                <i class="material-icons">mail_outline</i>
                            </button>
                        </div>
                        <!-- <button
                            mat-raised-button
                            type="button"
                            class="btn btn-success btn-link"
                            matTooltip="Edit"
                            matTooltipPosition="left"
                            (click)="editSchoolUser(element.id)"
                        >
                            <i class="material-icons">edit</i>
                        </button> -->
                        <a
                            mat-raised-button
                            class="btn btn-success btn-link"
                            matTooltip="Edit"
                            matTooltipPosition="left"
                            (click)="editUser(element.id)"
                        >
                            <i class="material-icons">edit</i>
                        </a>
                        <div
                            class="d-inline"
                            matTooltip="{{ element.id === userInfo.id ? 'You cannot delete yourself' : 'Delete' }}"
                            matTooltipPosition="left"
                        >
                            <button
                                mat-raised-button
                                [disabled]="element.id === userInfo.id"
                                type="button"
                                class="btn btn-danger btn-link"
                                (click)="deleteUser(element.id)"
                            >
                                <i class="material-icons">delete_outline</i>
                            </button>
                        </div>
                    </td>
                </ng-container>

                <!-- No Data -->
                <ng-container matColumnDef="noData">
                    <td
                        [hidden]="!dataSource || (dataSource && dataSource.filteredData && dataSource.filteredData.length > 0)"
                        mat-footer-cell
                        *matFooterCellDef
                        colspan="11"
                    >
                        {{ noDataInTable }}
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                <tr mat-footer-row *matFooterRowDef="['noData']" class="text-center"></tr>
                <!-- No Data-->
            </table>
            <app-spinner [loadPromise]="tableIsLoading"></app-spinner>
            <mat-paginator (page)="onPaginatorChange($event)"></mat-paginator>
        </div>
    </div>
</div>
