import { Injectable } from '@angular/core';
import { Organisation } from 'app/entities/organisation';

import { OrgInfo } from './org.model';
import { OrgStore } from './org.store';

@Injectable({ providedIn: 'root' })
export class OrgService {
    constructor(private orgStore: OrgStore) {}

    set(organisation: Organisation): void {
        this.orgStore.updateOrg(organisation);
    }

    getOrganisation(): OrgInfo {
        return this.orgStore.getValue();
    }

    getOrgName(): string {
        return this.orgStore.getValue().name;
    }

    resetStore() {
        return this.orgStore.reset();
    }
}
