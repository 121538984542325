import { CommonModule } from '@angular/common';
import { Component, Injector, Input, OnDestroy, OnInit, forwardRef } from '@angular/core';
import {
    ControlValueAccessor,
    FormsModule,
    NG_VALUE_ACCESSOR,
    NgControl,
    ReactiveFormsModule,
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { RouterModule } from '@angular/router';
import { Scheduling } from 'app/common/dto/email-template';
import { EmailTemplateTypeId } from 'app/common/enums';
import { Utils } from 'app/common/utils';
import { SharedModule } from 'app/shared.module';
import { SchoolQuery } from 'app/state/school';
import * as _ from 'lodash';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';
import { Observable, ReplaySubject, Subject, distinctUntilChanged, firstValueFrom, map, takeUntil, tap, withLatestFrom } from 'rxjs';

import {
    Schedule,
    TemplateInfo,
    TimeNumberId,
    TimeSpans,
    TimeUnit,
    defaultSchedule,
    findTimeSpanId,
    getScheduledLocalTime,
    getTemplateInfo,
    getTimeSpan,
    getTimeUnitRange,
    isTimeUnitDayOrMore,
} from './schedule-definitions';

type ChangeCallbackFn<T> = (value: T) => void;
type TouchCallbackFn = () => void;
type SetupData = { templateInfo: TemplateInfo; allowImmediateToggle: boolean; schedule: Schedule };
type TemplateData = {
    formValues: ScheduleFormGroupValues;
    timeRange: number[];
    showTimePicker: boolean;
    setup: SetupData;
};

export type ScheduleFormGroupValues = Scheduling & { timeNumberUnit: TimeNumberId | null };

@Component({
    selector: 'app-email-schedule',
    standalone: true,
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        MatInputModule,
        MatSelectModule,
        MatIconModule,
        NgxMatTimepickerModule,
        MatRadioModule,
        SharedModule,
    ],
    templateUrl: './email-schedule.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => EmailScheduleComponent),
            multi: true,
        },
    ],
})
export class EmailScheduleComponent implements ControlValueAccessor, OnInit, OnDestroy {
    @Input() typeId: EmailTemplateTypeId;
    profileFormGroup: UntypedFormGroup;
    TimeSpans = TimeSpans;
    timeUnits = Object.values(TimeUnit);
    campusTimezoneId$: Observable<string>;
    templateData$: Observable<TemplateData>;

    // The initial emit is done before the Observers subscribe. Using ReplaySubject with a buffersize of 1,
    // so the Observers still get this initial value.
    private setup$: ReplaySubject<SetupData> = new ReplaySubject(1);
    unsubscribe$ = new Subject<void>();
    constructor(private fb: UntypedFormBuilder, schoolQuery: SchoolQuery, private injector: Injector) {
        this.campusTimezoneId$ = schoolQuery.campuses$.pipe(
            map(campuses => Utils.getCurrentCampusTimeZoneId(campuses, Utils.getUserInfoFromToken().campusId))
        );
        this.profileFormGroup = this.fb.group({
            isImmediate: ['', Validators.required],
            scheduleMoment: [],
            scheduledLocalTime: [],
            timeNumberUnit: [],
            timeValue: [],
            timeUnit: [],
            isCustom: [],
        });

        this.templateData$ = (this.profileFormGroup.valueChanges as Observable<ScheduleFormGroupValues>).pipe(
            distinctUntilChanged(_.isEqual),
            withLatestFrom(this.setup$),
            map(([formValues, setup]) => {
                const syncedTimeFields = synchronizeTimeFields(formValues);
                return {
                    formValues: syncedTimeFields,
                    timeRange: getTimeUnitRange(syncedTimeFields.timeUnit),
                    showTimePicker: isTimeUnitDayOrMore(syncedTimeFields.timeUnit),
                    setup,
                };
            }),
            tap(({ formValues }) => this.profileFormGroup.patchValue(formValues))
        );
    }

    async ngOnInit() {
        const setup = await firstValueFrom(this.setup$);
        this.profileFormGroup.setValue(determineInitialFormValues(setup.schedule, setup.templateInfo));
        // Mark the control as pristine after setting the initial data
        const ngControl = this.injector.get(NgControl);
        ngControl.control.markAsPristine();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    /*** ControlValueAccessor methods **/
    // This method writeValue(...) can be called by Angular Forms before ngOnInit().
    writeValue(schedule: Schedule): void {
        this.setup$.next({ schedule, templateInfo: getTemplateInfo(this.typeId), allowImmediateToggle: allowImmediateToggle(this.typeId) });
    }

    registerOnChange(fn: ChangeCallbackFn<object>): void {
        this.profileFormGroup.valueChanges.pipe(takeUntil(this.unsubscribe$), distinctUntilChanged(_.isEqual)).subscribe(fn);
    }

    registerOnTouched(fn: TouchCallbackFn): void {
        this.onTouched = fn;
    }

    onTouched: () => void = () => {};

    setDisabledState?(isDisabled: boolean): void {
        isDisabled ? this.profileFormGroup.disable() : this.profileFormGroup.enable();
    }
}

const allowImmediateToggle = (typeId: EmailTemplateTypeId) =>
    ![EmailTemplateTypeId.GeneralEnquiry, EmailTemplateTypeId.InformationPack].includes(typeId);

const synchronizeTimeFields: (values: ScheduleFormGroupValues) => ScheduleFormGroupValues = (values: ScheduleFormGroupValues) => ({
    ...values,
    ...handleTimeNumberUnitChanges(_.pick(values, 'timeUnit', 'timeValue', 'timeNumberUnit')),
});

type TimeValues = Pick<ScheduleFormGroupValues, 'timeUnit' | 'timeValue' | 'timeNumberUnit'>;
type ChangedValues = Partial<Pick<ScheduleFormGroupValues, 'isCustom' | 'timeUnit' | 'timeValue'>>;
function handleTimeNumberUnitChanges(current: TimeValues): ChangedValues {
    if (current.timeNumberUnit !== 'custom') {
        const timeSpan = getTimeSpan(current.timeNumberUnit);
        return { isCustom: false, timeValue: timeSpan.value, timeUnit: timeSpan.unit };
    } else {
        const correctedTimeValue = Math.min(current.timeValue, Math.max(...getTimeUnitRange(current.timeUnit)));
        return { isCustom: true, timeValue: correctedTimeValue };
    }
}

function determineInitialFormValues(val: Schedule, templateInfo: TemplateInfo): ScheduleFormGroupValues {
    const timeValue = val.isImmediate ? defaultSchedule.timeValue : val.timeValue;
    const timeUnit = val.isImmediate ? defaultSchedule.timeUnit : val.timeUnit;

    return {
        isImmediate: val.isImmediate,
        timeValue: timeValue,
        timeUnit: timeUnit,
        isCustom: val.isCustom,
        scheduledLocalTime:
            !val.isImmediate && isTimeUnitDayOrMore(val.timeUnit)
                ? getScheduledLocalTime(val.scheduledLocalTime)
                : defaultSchedule.scheduledLocalTime,
        scheduleMoment: val.scheduleMoment ?? templateInfo.other.find(i => i.default).value,
        timeNumberUnit: val.isCustom ? 'custom' : findTimeSpanId(timeUnit, timeValue),
    };
}
