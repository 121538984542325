export const defaults = {
    brand: {
        name: 'SchoolMint Connect',
        companyName: 'SchoolMint',
        title: 'SchoolMint Connect',
        supportEmail: 'connect-support@schoolmint.net',
        contactEmail: 'connect-support@schoolmint.net',
        privacyPolicyUrl: 'https://schoolmint.com/privacy-policy/',
        termsOfUseUrl: 'https://schoolmint.com/terms-of-services/',
        homeUrl: 'https://schoolmint.com/',
        whatsNewUrl: 'https://schoolmintconnect.zendesk.com/hc/en-us/categories/4404737498011-What-s-New',
        exportFilenamePrefix: 'SchoolMintConnect',
        footerText: 'Powered by Enquiry Tracker',
        hideAppModuleIfNotEnabled: true,
        zendeskAccountUrl: 'schoolmintconnect.zendesk.com',
        supportGA4ArticleUrl: 'https://schoolmintconnect.zendesk.com/hc/en-us/articles/12287838368795',
        supportGA3ArticleUrl: '',
    },
};
