import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Constants } from 'app/common/constants';
import { HttpService } from 'app/services/http.service';
import { environment } from 'environments/environment';
import { from } from 'rxjs';

import { SpinnerModule } from '../spinner/spinner.module';

@Component({
    selector: 'app-app-info',
    standalone: true,
    imports: [CommonModule, SpinnerModule],
    templateUrl: './app-info.component.html',
    styleUrls: ['./app-info.component.scss'],
})
export class AppInfoComponent {
    public brand = environment.brand;
    public appVersions$ = from(
        this.httpService.getAuth('setting/get-versions').then((versions: any) => ({
            build: Constants.build,
            version: Constants.version,
            dbBuild: String(versions.DB_VERSION).padStart(4, '0'),
            backendBuild: String(versions.BUILD_VERSION).padStart(4, '0'),
        }))
    );

    loadingPromise = new Promise(() => {});

    constructor(private httpService: HttpService, public activeModal: NgbActiveModal) {}
}
