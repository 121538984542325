import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Constants } from 'app/common/constants';
import * as _ from 'lodash';
import { ReplaySubject, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-select-search',
    templateUrl: './select-search.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SelectSearchComponent),
            multi: true,
        },
    ],
})
export class SelectSearchComponent implements ControlValueAccessor, OnInit, OnChanges, OnDestroy {
    public searchSelectForm: UntypedFormGroup;
    noItemSelected = Constants.noItemSelected;
    filteredList: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
    listFilterCtrl: UntypedFormControl = new UntypedFormControl();
    _onDestroy = new Subject<void>();
    subFormChanges: Subscription;
    selectedItem;

    @Input() placeholder;
    @Input() isRequired = false;
    @Input() hasAlternativeName = false;
    @Input() list: any[];
    @Output() outputData = new EventEmitter();
    @Input() translateText: string = 'yes';
    @Input() showSubtitle = false;
    @Input() hideRequired = false;

    set value(val) {
        if (this.searchSelectForm) {
            this.searchSelectForm.controls.val.setValue(val);
        }
        this.onChange(val);
        this.onTouched();
    }

    onTouched: any = () => {};
    onChange: any = () => {};

    constructor(private fb: UntypedFormBuilder) {}

    writeValue(value: any) {
        this.value = value;
        this.setSelectedItem(value);
        this.subscribeFormChanges(); // subscribe when form is populated
    }

    subscribeFormChanges() {
        if (this.subFormChanges) {
            this.subFormChanges.unsubscribe();
        }
        this.subFormChanges = this.searchSelectForm.get('val').valueChanges.subscribe(val => {
            this.setSelectedItem(val);
            this.outputData.emit({ value: val });
        });
    }

    ngOnInit() {
        this.createSearchSelectForm();
        this.filteredList.next(this.list.slice());
        this.listFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
            this.filterList();
        });
    }

    private createSearchSelectForm() {
        this.searchSelectForm = this.fb.group({ val: [''] });
    }

    setSelectedItem(value) {
        this.selectedItem = _.find(this.list, item => item.id === value);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes && changes.list) {
            this.filteredList.next(this.list.slice());
        }
    }

    registerOnChange(fn: (val: any) => any) {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => any) {
        this.onTouched = fn;
    }

    ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete();
        if (this.subFormChanges) {
            this.subFormChanges.unsubscribe();
        }
    }

    private filterList() {
        if (!this.list) {
            return;
        }
        let search = this.listFilterCtrl.value;
        if (!search) {
            this.filteredList.next(this.list.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        this.filteredList.next(this.list.filter(country => country.name.toLowerCase().indexOf(search) > -1));
    }
}
