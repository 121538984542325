import { Directive, EventEmitter, OnDestroy, Output } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseTable } from 'app/base-table';
import { Constants } from 'app/common/constants';
import { ModalAction, UserType } from 'app/common/enums';
import { UserInfo } from 'app/entities/userInfo';
import { StorageService } from 'app/services/storage.service';
import { UserQuery } from 'app/state/user';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';

import { ISelectUserData } from '../../common/interfaces';
import { Colors, Utils } from '../../common/utils';
import { ETUser, User } from '../../entities/user';
import { HttpService } from '../../services/http.service';
import { SelectUserDialogComponent } from '../select-user-dialog/select-user-dialog.component';
import { UserService } from '../user-details/user.service';

declare const $: any;
@Directive()
export abstract class BaseUsers<T extends ETUser> extends BaseTable<T> implements OnDestroy {
    users: Array<T> = [];
    tableId: string;
    cardTitle: string;
    userInfo: UserInfo = null;
    dateDelimiterTime = Constants.localeFormats.dateDelimiterTime;

    selectUserData: ISelectUserData = <ISelectUserData>{ htmlId: 'selectUserSchoolUser' };
    selectUserSub: Subscription;
    @Output() usersListChanged = new EventEmitter();
    @Output() userReassigned = new EventEmitter();

    constructor(
        protected userQuery: UserQuery,
        protected router: Router,
        protected httpService: HttpService,
        protected dialog: MatDialog,
        storageService: StorageService,
        protected modalService: NgbModal,
        protected userType: UserType,
        protected userService: UserService<T>
    ) {
        super(userQuery, storageService);
        this.userInfo = Utils.getUserInfoFromToken();
    }

    protected buildTable(users) {
        users.forEach(u => {
            u.name = u.lastName + ', ' + u.firstName;
        });
        super.buildTable(users);
        this.updateTable(users);
    }

    updateSendNotifications(sendNotifications: boolean, id: number): void {
        const user: User = new User();
        user.id = id;
        user.sendNotifications = sendNotifications;
        this.userService
            .updateNotifications(user)
            .then(() => {
                Utils.showSuccessNotification();
            })
            .catch(console.error);
    }

    ngOnDestroy() {
        if (this.selectUserSub) {
            this.selectUserSub.unsubscribe();
        }
    }

    inviteUser(email: string) {
        this.httpService
            .postAuth('users/invite', { email: email })
            .then(res => {
                Utils.showNotification('Invite message has been sent to user', Colors.success);
            })
            .catch(err => {
                console.log(err);
            });
    }

    protected add(component) {
        const userDetailModalRef = this.modalService.open(component, Constants.ngbModalLg);
        userDetailModalRef.componentInstance.cardTitle = this.cardTitle;
        userDetailModalRef.result.then((result: { action: ModalAction; user?: T }) => {
            switch (result.action) {
                case ModalAction.Create:
                    this.users.push(result.user);
                    this.usersListChanged.emit(this.users);
                    this.buildTable(this.users);
                    break;
                default:
                    break;
            }
        });
    }

    protected edit(userId: number, component) {
        const userDetailModalRef = this.modalService.open(component, Constants.ngbModalLg);
        userDetailModalRef.componentInstance.cardTitle = this.cardTitle;
        userDetailModalRef.componentInstance.userId = this.users.find(user => user.id === userId)?.id;
        userDetailModalRef.result.then((result: { action: ModalAction; user?: T; targetUserId?: number; sourceUserId?: number }) => {
            const id: number = result?.user?.id;
            switch (result.action) {
                case ModalAction.Update:
                    const userId = _.findIndex(this.users, u => u.id === id);
                    this.users[userId] = result.user;
                    this.usersListChanged.emit(this.users);
                    if (result.targetUserId && result.sourceUserId) {
                        this.userReassigned.emit({ targetUserId: result.targetUserId, sourceUserId: result.sourceUserId });
                    }
                    this.buildTable(this.users);
                    break;
                default:
                    break;
            }
        });
    }

    deleteUser(userId: number) {
        this.userService.deletable(userId, this.userType).then((isDeletable: boolean) => {
            if (isDeletable) {
                const self = this;
                return Swal.fire({
                    title: 'Are you sure?',
                    html: 'You will not be able to revert this! To continue you must assign any new tasks or events to another user.\
                            <br><br>\
                            <button class="btn btn-cancel cancel">Cancel</button>\
                            <button class="btn btn-success select-user">Select User</button>',
                    type: 'warning',
                    showConfirmButton: false,
                    onOpen(swalSchoolUsers: HTMLElement) {
                        $(swalSchoolUsers)
                            .find('.select-user')
                            .off()
                            .click(e => {
                                Swal.close();
                                const users = _.filter(self.users, (item: T) => item.activated === true);
                                _.remove(users, (item: T) => item.id === userId);
                                self.selectUserData.users = users;
                                // eslint-disable-next-line max-len
                                const selctUsermDialogRef = self.dialog.open(
                                    SelectUserDialogComponent,
                                    SelectUserDialogComponent.getDialogConfig(self.selectUserData)
                                );
                                self.selectUserSub = selctUsermDialogRef.afterClosed().subscribe(id => {
                                    if (id !== undefined) {
                                        self.userService
                                            .reassignUser(userId, id)
                                            .then(() => {
                                                self.userReassigned.emit({ targetUserId: id, sourceUserId: userId });
                                                self.userService
                                                    .deleteUser(userId)
                                                    .then(() => {
                                                        self.afterDeleted(userId);
                                                        Utils.deletedSuccessfully('User has been deleted.');
                                                    })
                                                    .catch(err => console.log(err));
                                            })
                                            .catch(err => console.log(err));
                                    } else {
                                        return false;
                                    }
                                });
                            });
                        $(swalSchoolUsers)
                            .find('.cancel')
                            .off()
                            .click(e => {
                                Swal.close();
                                return false;
                            });
                    },
                });
            } else {
                Utils.confirmDelete(() => this.userService.deleteUser(userId), '', 'User has been deleted.').then((deleted: boolean) => {
                    if (deleted) this.afterDeleted(userId);
                });
            }
        });
    }

    private afterDeleted(userId: number) {
        _.remove(this.users, (user: T) => user.id === userId);
        this.usersListChanged.emit(this.users);
        this.buildTable(this.users);
    }
}
