import { Injectable } from '@angular/core';
import { ChartSection, ChartTypeSelector, DrillDownStatus } from 'app/common/interfaces';
import { Legend } from 'app/entities/local/legend';
import * as _ from 'lodash';
import * as moment from 'moment';

import {
    ChartMetaData,
    ChartRangeSelector,
    ChartViewDetails,
    createChartViewDetailsObj,
    getApplicationLegends,
} from '../../common/chart-utils';
import { Constants } from '../../common/constants';
import { ChartType } from '../../common/enums';
import { Keys } from '../../common/keys';
import { T } from '../../common/t';
import { ChartEntity } from './chart.entity';
import { ChartStore } from './chart.store';

@Injectable({ providedIn: 'root' })
export class ChartService {
    constructor(private chartStore: ChartStore) {}

    add(chartEntity: ChartEntity) {
        this.chartStore.add(chartEntity);
    }

    update(id: string, chartEntity: Partial<ChartEntity>) {
        this.chartStore.update(id, chartEntity);
    }

    updateLegend(id: string, legend: Legend) {
        this.chartStore.update(id, entity => {
            const legends = _.cloneDeep(entity.chartRawData.legends);
            _.forEach(legends, (temp: Legend) => {
                if (legend?.name === temp.name) {
                    temp.isSelected = !legend.isSelected;
                }
            });
            let legendIds: (number | string)[] = _.map(
                _.filter(legends, (l: Legend) => l.isSelected),
                'id'
            );
            if (id.includes(Keys.appsByStartingYear)) {
                legendIds = _.map(
                    _.filter(getApplicationLegends(legends), (l: Legend) => l.isSelected),
                    'name'
                );
            }
            let intakeYearLevels = entity.filter?.intakeYearLevels;
            // Legend Ids represent a intake year level for enquiry by month charts.
            if (
                id.includes(Keys.enquiryByMonth) ||
                ((id.includes(Keys.declineByReason) || id.includes(Keys.hearAboutUs) || id.includes(Keys.leadSource)) &&
                    entity.chartTypeSelector.type !== ChartType.Pie)
            ) {
                intakeYearLevels = legendIds as number[];
            }

            // Legend Ids represent a stage of enquiry for Funnel metricy by starting year chart.
            let selectedStages = entity.filter?.selectedStages;
            if (id.includes(Keys.funnelMetricsByStartingYear)) {
                selectedStages = legendIds as number[];
            }
            return {
                chartRawData: {
                    ...entity.chartRawData,
                    legends,
                },
                chartViewDetails: this.getChartViewDetails(id, entity, legends),
                filter: {
                    ...entity.filter,
                    legendId: legendIds,
                    intakeYearLevels,
                    selectedStages,
                },
            };
        });
    }

    updateLegendSection(id: string) {
        this.chartStore.update(id, entity => {
            const legends = _.cloneDeep(entity.chartRawData.legends);
            _.forEach(legends, (temp: Legend) => {
                temp.isSelected = true;
            });
            const legendIds = _.map(legends, 'id');
            let intakeYearLevels = entity.filter?.intakeYearLevels;
            if (
                id.includes(Keys.enquiryByMonth) ||
                (id.includes(Keys.declineByReason) && entity.chartTypeSelector.type !== ChartType.Pie)
            ) {
                intakeYearLevels = legendIds;
            }
            // Legend Ids represent a stage of enquiry for Funnel metricy by starting year chart.
            let selectedStages = entity.filter?.selectedStages;
            if (id.includes(Keys.funnelMetricsByStartingYear)) {
                selectedStages = legendIds;
            }
            return {
                chartRawData: {
                    ...entity.chartRawData,
                    legends,
                },
                hasLegend: !entity.hasLegend,
                chartViewDetails: this.getChartViewDetails(id, entity, legends),
                filter: {
                    ...entity.filter,
                    legendId: legendIds,
                    intakeYearLevels,
                    selectedStages,
                },
            };
        });
    }

    updateDrillDownStatus(id: string, drillDownStatus: DrillDownStatus, chartTypeSelector: ChartTypeSelector, isSelected: boolean) {
        this.chartStore.update(id, entity => {
            let legendIds: number[];
            if (drillDownStatus) {
                legendIds = _.map(
                    _.filter(entity.chartRawData.legends, (l: Legend) => l.name === drillDownStatus?.legendName),
                    'id'
                );
            } else {
                legendIds = _.map(entity.chartRawData.legends, 'id');
            }
            return {
                ...entity,
                chartTypeSelector,
                drillDownStatus,
                aggregateSection: {
                    ...entity.aggregateSection,
                    isSelected,
                },
                filter: {
                    ...entity.filter,
                    legendId: legendIds,
                },
            };
        });
    }

    updateChartRangeSelector(id: string, chartRangeSelector: ChartRangeSelector) {
        this.chartStore.update(id, entity => {
            return {
                ...entity,
                chartRangeSelector,
                filter: {
                    ...entity.filter,
                    startCreatedDate: moment(chartRangeSelector.startDate).format(Constants.dateFormats.dateTimeUTC),
                    endCreatedDate: moment(chartRangeSelector.endDate).format(Constants.dateFormats.dateTimeUTC),
                },
            };
        });
    }

    updateChartMetaDataLegendStatus(id: string, legend: Legend) {
        this.chartStore.update(id, entity => {
            const legends = _.cloneDeep(entity.chartMetaData.legends);
            _.forEach(legends, (l: Legend) => {
                if (legend?.id === l.id) {
                    l.isSelected = !legend.isSelected;
                }
            });
            return {
                ...entity,
                chartMetaData: {
                    ...entity.chartMetaData,
                    legends,
                },
            };
        });
    }

    updateChartMetaData(id: string, chartMetaData: ChartMetaData) {
        this.chartStore.update(id, entity => {
            return {
                ...entity,
                chartMetaData,
            };
        });
    }

    updateChartAggregateSection(id: string, aggregateSection: ChartSection) {
        this.chartStore.update(id, entity => {
            return {
                ...entity,
                aggregateSection,
            };
        });
    }

    updateChartViewDetails(id: string, chartViewDetails: ChartViewDetails) {
        this.chartStore.update(id, { chartViewDetails });
    }

    setChartTypeSelector(id: string, chartTypeSelector: ChartTypeSelector) {
        this.chartStore.update(id, entity => {
            const aggregateSection = _.cloneDeep(entity.aggregateSection);
            if (
                id.includes(Keys.declineByReason) ||
                id.includes(Keys.hearAboutUs) ||
                id.includes(Keys.leadSource) ||
                id.includes(Keys.reasonForSelection)
            ) {
                aggregateSection.hasButton = true;
                if (chartTypeSelector.type === ChartType.HorizontalBar) {
                    aggregateSection.hasButton = false;
                }
            } else if (id.includes(Keys.appsByStartingYear) || id.includes(Keys.funnelMetricsByStartingYear)) {
                aggregateSection.hasButton = true;
                if (chartTypeSelector.type === ChartType.Line) {
                    aggregateSection.hasButton = false;
                }
            }
            return {
                chartTypeSelector,
                aggregateSection,
            };
        });
    }

    private getChartViewDetails(id: string, entity: ChartEntity, legends: Legend[]): ChartViewDetails {
        if (id.includes(Keys.declineByReason) || id.includes(Keys.hearAboutUs) || id.includes(Keys.leadSource)) {
            const isDisabled = entity.chartTypeSelector.type === ChartType.Pie ? _.some(legends, l => !l.isSelected) : false;
            return createChartViewDetailsObj(isDisabled, T.disableViewDetailsBtnTooltip);
        } else if (id.includes(Keys.yearlyComparisonOfFormsReceived)) {
            const isDisabled = _.some(legends, l => !l.isSelected);
            return createChartViewDetailsObj(isDisabled, T.disableViewDetailsBtnTooltip);
        } else {
            return createChartViewDetailsObj(entity.chartViewDetails?.isDisabled, entity.chartViewDetails?.tooltip);
        }
    }
}
