import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatSortModule } from '@angular/material/sort';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserType } from 'app/common/enums';
import { Utils } from 'app/common/utils';
import { User } from 'app/entities/user';
import { StorageService } from 'app/services/storage.service';
import { SharedModule } from 'app/shared.module';
import { UserQuery } from 'app/state/user';

import { HttpService } from '../../services/http.service';
import { SpinnerModule } from '../spinner/spinner.module';
import { SystemUserDetailsComponent } from '../user-details/system-user-details.component';
import { UserService } from '../user-details/user.service';
import { BaseUsers } from './base-users';

@Component({
    selector: 'app-table-system-users',
    standalone: true,
    imports: [
        CommonModule,
        SpinnerModule,
        MatPaginatorModule,
        MatInputModule,
        MatTableModule,
        SharedModule,
        MatCheckboxModule,
        MatTooltipModule,
        MatSortModule,
    ],
    templateUrl: './users.component.html',
    providers: [UserService<User>],
})
export class SystemUsersComponent extends BaseUsers<User> implements OnInit {
    tableId = 'systemUsersTable';
    icon = 'person';
    cardTitle = 'System';

    constructor(
        protected userQuery: UserQuery,
        router: Router,
        httpService: HttpService,
        dialog: MatDialog,
        storageService: StorageService,
        modalService: NgbModal,
        userService: UserService<User>
    ) {
        super(userQuery, router, httpService, dialog, storageService, modalService, UserType.SystemUser, userService);
        this.displayedColumns = ['name', 'email', 'activated', 'role', 'lastLogin', 'actions'];
    }

    async ngOnInit() {
        this.tableIsLoading = this.userService.getSysUsers().then((users: User[]) => {
            this.users = users;
            this.buildTable(this.users);
        });
    }

    addUser() {
        this.add(SystemUserDetailsComponent);
    }

    editUser(userInfo: number) {
        this.edit(userInfo, SystemUserDetailsComponent);
    }
}
