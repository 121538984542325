import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { getLeafRoute } from 'app/common/router-utils';
import { StorageService } from 'app/services/storage.service';
import { UserStateService } from 'app/state/user/user-state.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Subject, Subscription } from 'rxjs';
import { filter, startWith, takeUntil } from 'rxjs/operators';

import { Utils } from '../../common/utils';
import { Campus } from '../../entities/campus';
import { UserInfo } from '../../entities/userInfo';
import { HttpService } from '../../services/http.service';
import { ListenerService } from '../../services/listener.service';
import { StateService } from '../../services/state.service';

@Component({
    selector: 'app-campus-switcher-cmp',
    standalone: true,
    imports: [CommonModule, MatSelectModule, TranslateModule],
    templateUrl: 'campus-switcher.component.html',
    styleUrls: ['campus-switcher.component.scss'],
})
export class CampusSwitcherComponent implements OnInit, OnDestroy {
    campuses: (Campus | any)[] = [];
    campus: Campus | any;
    private mainCampus: Campus;
    private userInfo: UserInfo = null;
    private subscrCampusList: Subscription;
    private destroy$ = new Subject<void>();
    public showCampusSwitcher: boolean;
    public showUndecided: boolean;
    public disabled: boolean;

    constructor(
        private httpService: HttpService,
        private router: Router,
        private listenerService: ListenerService,
        private ref: ChangeDetectorRef,
        private storageService: StorageService,
        private stateService: StateService,
        private userStateService: UserStateService
    ) {
        this.subscrCampusList = this.listenerService.campusListStatus().subscribe(() => {
            this.onChangeCampuses();
        });

        this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd),
                // need to jump start observable if navigation ends before construction, e.g. direct navigation
                startWith(undefined),
                takeUntil(this.destroy$)
            )
            .subscribe(() => {
                const route = getLeafRoute(this.router.routerState.snapshot);
                const switcherData = route.data.campusSwitcher;
                this.showCampusSwitcher = switcherData?.shouldShow ?? true;
                this.showUndecided = switcherData?.hasUndecided ?? true;
                this.disabled = switcherData?.isDisabled ?? true;
                this.checkUndecided();
            });
    }

    ngOnInit() {
        this.getCampuses().then(() => {
            this.updateLocalCampus();
            this.setCampusTimeZone();
            this.checkUndecided();
        });
    }

    ngOnDestroy() {
        this.subscrCampusList.unsubscribe();
        this.destroy$.next();
        this.destroy$.complete();
    }

    private getCampuses(): Promise<void> {
        return this.httpService.getAuth('campus').then((data: Campus[]) => {
            this.campuses = data;
            this.campuses = _.sortBy(this.campuses, ['sequence']);
            this.mainCampus = _.find(this.campuses, (item: Campus) => item.campusType === Campus.CAMPUS_TYPE_MAIN);
            const undecidedCampus = _.find(this.campuses, (item: Campus) => item.campusType === Campus.CAMPUS_TYPE_UNDECIDED);
            this.campuses = _.remove(this.campuses, (item: Campus) => item.campusType !== Campus.CAMPUS_TYPE_UNDECIDED);
            this.campuses.push(undecidedCampus);
            return Promise.resolve();
        });
    }

    private onChangeCampuses() {
        this.getCampuses().then(() => {
            this.userInfo = Utils.getUserInfoFromToken();
            if (this.userInfo.campusId) {
                this.campus = this.getCampus(this.userInfo.campusId);
                this.campusChanged(this.campus.id);
            } else {
                this.campus = this.getCampusAllItem();
            }
            this.setCampusTimeZone();
        });
    }

    isMobileMenu() {
        return Utils.isMobileSize();
    }

    campusChanged(campusId: number | string) {
        if (this.userInfo.campusId !== campusId) {
            // if campus is already not chosen
            this.storageService.resetFilters();
            this.storageService.resetTableStates();
            this.stateService.resetFilter();
            this.userStateService
                .setCampus(campusId === 'all' ? null : +campusId)
                .then(() => {
                    this.updateLocalCampus();
                    this.setCampusTimeZone();
                    this.listenerService.campusSelectChanged();
                    this.listenerService.sidebarToggled();
                })
                .catch(err => {
                    console.log(err);
                });
        }
    }

    private updateLocalCampus() {
        this.userInfo = Utils.getUserInfoFromToken();
        this.campus = this.userInfo.campusId ? this.getCampus(this.userInfo.campusId) : this.getCampusAllItem();
        Utils.DetectChanges(this.ref);
    }

    private getCampus(id: number) {
        return _.find(this.campuses, c => c.id === id) ?? this.mainCampus;
    }

    private getCampusAllItem(): Campus | { id: string } {
        return { id: 'all', name: 'All', campusType: 'normal', timeZoneId: this.mainCampus.timeZoneId };
    }

    checkUndecided() {
        this.userInfo = Utils.getUserInfoFromToken();
        if (!this.showUndecided && this.userInfo?.campusId && this.campuses.length) {
            if (Campus.CAMPUS_TYPE_UNDECIDED === _.find(this.campuses, (item: Campus) => item.id === this.userInfo.campusId).campusType) {
                this.campusChanged('all');
            }
        }
    }

    /**
     * Set default moment timezone by current campus timezone
     */
    private setCampusTimeZone(): void {
        moment.tz.setDefault(this.campus.timeZoneId);
    }
}
