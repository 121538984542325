import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import * as _ from 'lodash';

import { ExportType, SupportedExportType } from '../../common/enums';
import { Campus } from '../../entities/campus';
import { SchoolInfo } from './school.model';
import { SchoolStore } from './school.store';

@Injectable({ providedIn: 'root' })
export class SchoolQuery extends Query<SchoolInfo> {
    startingMonth$ = this.select(state => state.startingMonth);
    googleTrackingIsEnabled$ = this.select(state => state.googleTrackingIsEnabled);
    school$ = this.select(state => state);
    timeZone$ = this.select(state => state.timeZoneId);
    managementSystem$ = this.select(state => state.managementSystem);
    exportType$ = this.select(state => state.exportType);
    isBoarding$ = this.select(state => state.isBoardingEnabled);
    isTransferAllowed$ = this.select(
        state =>
            state.exportType === ExportType.Transfer &&
            state.managementSystem.supportedExportType === SupportedExportType.DownloadAndTransfer
    );
    campuses$ = this.select(state => {
        const campuses = _.orderBy(state.campuses, ['sequence']);
        campuses.push(
            ...campuses.splice(
                campuses.findIndex(v => v.campusType === Campus.CAMPUS_TYPE_UNDECIDED),
                1
            )
        );
        return campuses;
    });
    campusesWithoutUndecided$ = this.select(state =>
        _.orderBy(
            _.filter(state.campuses, c => c.campusType !== Campus.CAMPUS_TYPE_UNDECIDED),
            ['sequence']
        )
    );

    constructor(protected store: SchoolStore) {
        super(store);
    }
}
