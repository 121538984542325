import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { Utils } from 'app/common/utils';
import { CustomHttpParams } from 'app/entities/custom-http-params';
import { School } from 'app/entities/school';
import { UserInfo } from 'app/entities/userInfo';
import { SchoolsService } from 'app/org/assigned-schools/schools.service';
import { HttpService } from 'app/services/http.service';
import { ListenerService } from 'app/services/listener.service';
import { StateService } from 'app/services/state.service';
import { StorageService } from 'app/services/storage.service';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';

import { UserStateService } from '../../state/user/user-state.service';
import { ComponentsModule } from '../components.module';

export abstract class SchoolService {
    abstract getSchools: () => Promise<School[]>;
}

const schoolServiceFactory = (schoolsService: SchoolsService) => {
    const actor = Utils.getUserInfoFromToken();
    if (actor.isSysAdmin()) {
        return {
            getSchools: async () => {
                return schoolsService.getSchoolsList();
            },
        };
    } else {
        return {
            getSchools: async () => {
                const params = new CustomHttpParams()
                    .generateFields(['id', 'name', 'organisationId', 'state', 'city', 'sublocality'])
                    .generateIncludes(['administrativeArea']);
                return schoolsService.getOrgSchools(actor.organisationId, params);
            },
        };
    }
};

type SchoolSelectItem = School & { subtitle: string };

@Component({
    selector: 'app-school-switcher-cmp',
    standalone: true,
    imports: [CommonModule, FormsModule, ReactiveFormsModule, ComponentsModule],
    templateUrl: 'school-switcher.component.html',
    styleUrls: ['school-switcher.component.scss'],
    providers: [{ provide: SchoolService, useFactory: schoolServiceFactory, deps: [SchoolsService] }],
})
export class SchoolSwitcherComponent implements OnInit, OnDestroy {
    selectSchoolForm: FormGroup;
    userInfo: UserInfo = null;
    schools: SchoolSelectItem[] = [];
    school: School = null;

    subscrSchoolList: Subscription;

    constructor(
        private httpService: HttpService,
        private router: Router,
        private listenerService: ListenerService,
        private stateService: StateService,
        private storageService: StorageService,
        private fb: FormBuilder,
        private userStateService: UserStateService,
        private schoolService: SchoolService
    ) {
        this.subscrSchoolList = this.listenerService.schoolListStatus().subscribe(async () => {
            await this.getInfo();
            this.selectSchoolForm.controls.schoolId.setValue(this.school.id);
        });
    }

    async ngOnInit() {
        this.userInfo = Utils.getUserInfoFromToken();
        this.httpService.updateCurrentSchoolId(this.userInfo.schoolId);
        await this.getInfo();
        this.selectSchoolForm = this.fb.group({
            schoolId: [this.school.id],
        });
    }

    ngOnDestroy() {
        this.subscrSchoolList.unsubscribe();
    }

    private async getInfo(): Promise<void> {
        if (!this.userInfo.isAuthorizedToSwitch()) {
            return;
        }

        let schools: School[] = _.sortBy(await this.schoolService.getSchools(), school => _.lowerCase(school.name));
        this.schools = this.toSchoolSelectItems(schools);
        this.school = this.schools.find(school => school.id === this.userInfo.schoolId);
    }

    private toSchoolSelectItems(schools: School[]): SchoolSelectItem[] {
        return schools.map(school => {
            const subtitleComponents = [school.city, school.administrativeArea?.name].filter(c => c);
            return {
                ...school,
                subtitle: subtitleComponents.join(', '),
            };
        });
    }

    isMobileMenu() {
        return Utils.isMobileSize();
    }

    schoolChanged(schoolId: number) {
        if (this.userInfo.schoolId !== schoolId) {
            this.storageService.resetFilters();
            this.storageService.resetTableStates();
            // TODO: stateService should be removed in the future
            this.stateService.resetFilter();
            this.setUserSchoolAndRefreshToken(schoolId)
                .then(() => {
                    this.getInfo().then(() => {
                        this.router.navigate(['dashboard/sendback']).then(() => {
                            this.router.navigate(['dashboard']);
                            this.listenerService.eventListChanged();
                            this.listenerService.campusListChanged();
                            this.listenerService.schoolSelectChanged();
                            Utils.resetSession();
                        });
                    });
                })
                .catch(err => {
                    console.log(err);
                });
        } else {
            console.log('school is already chosen');
        }
    }

    private async setUserSchoolAndRefreshToken(schoolId: number): Promise<void> {
        await this.userStateService.setSchool(schoolId);
        this.userInfo = Utils.getUserInfoFromToken();
    }
}
