<div class="modal-body">
    <h5 *ngIf="appVersions$ | async as appVersions; else isLoading">
        {{ brand.name }}<br />
        Version {{ appVersions.version }}<br />
        <a href="{{ brand.whatsNewUrl }}" target="_blank">What’s new ?</a><br />
        (Frontend Build {{ appVersions.build }}, Backend Build {{ appVersions.backendBuild }}, Database Build {{ appVersions.dbBuild }})
    </h5>
    <ng-template #isLoading>
        <app-spinner [loadPromise]="loadingPromise"></app-spinner>
    </ng-template>

    <div>
        We are always working to make our products better and your feedback is critical to making them great. To send us your feedback, just
        click the Help button at the top right corner of the screen.
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-success mx-auto" (click)="activeModal.close()">Ok</button>
</div>
