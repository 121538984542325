<div class="modal-header">
    <h4 class="modal-title text-start" id="selectStudentModalLabel">{{ title }}</h4>
    <button type="button" class="btn-close" tabindex="-1" aria-label="Close" [promiseBtn]="promiseForBtn" (click)="onCancel()"></button>
</div>
<div class="modal-body">
    <form [formGroup]="formGroup" novalidate (ngSubmit)="onSubmit()" *ngIf="loaded">
        <div class="row gy-3 align-items-center mb-3">
            <div class="col-lg-6">
                <mat-form-field>
                    <input
                        id="first-name"
                        matInput
                        hide-autocomplete
                        placeholder="First Name"
                        type="text"
                        formControlName="firstName"
                        required
                    />
                    <mat-error [hidden]="formGroup.controls.firstName.valid || formGroup.controls.firstName.pristine" class="text-danger">
                        First Name is required and should contain at least {{ requiredTextFieldMinLength }} and at most
                        {{ length50 }} characters.
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-lg-6">
                <mat-form-field>
                    <input
                        id="last-name"
                        matInput
                        hide-autocomplete
                        placeholder="Last Name"
                        type="text"
                        formControlName="lastName"
                        required
                    />
                    <mat-error [hidden]="formGroup.controls.lastName.valid || formGroup.controls.lastName.pristine">
                        Last Name is required and should contain at least {{ requiredTextFieldMinLength }} and at most
                        {{ length50 }} characters.
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-lg-6">
                <mat-form-field>
                    <input id="email" matInput hide-autocomplete placeholder="Email" type="email" formControlName="email" required />
                    <mat-error [hidden]="formGroup.controls.email.valid || formGroup.controls.email.pristine">
                        Email format should be
                        <i>john@doe.com</i> and contain at most {{ emailMaxLength }} characters.
                    </mat-error>
                    <mat-hint class="text-warning" *ngIf="!formGroup.value.id; else messageDisabled"
                        >Note: email address cannot be changed once user is created</mat-hint
                    >
                </mat-form-field>
                <ng-template #messageDisabled>
                    <mat-hint>Email address cannot be changed for existing users</mat-hint>
                </ng-template>
            </div>
            <div class="col-lg-6">
                <mat-form-field>
                    <input id="job-title" matInput hide-autocomplete placeholder="Job Title" type="text" formControlName="title" required />
                    <mat-error>
                        Title is required and should contain at least {{ requiredTextFieldMinLength }} and at most
                        {{ length60 }} characters.
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-lg-6">
                <mat-form-field>
                    <mat-select id="access-level" placeholder="Access Level" formControlName="role" required>
                        <mat-option *ngFor="let role of roles" [value]="role">
                            {{ role }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-lg-6" *ngIf="shouldAllowSecondaryRole">
                <mat-form-field>
                    <mat-select id="secondary-access-level" placeholder="Secondary Access Level" formControlName="secondaryRole">
                        <mat-option [value]="null">
                            {{ noItemSelected }}
                        </mat-option>
                        <mat-option *ngFor="let role of secondaryRoles" [value]="role">
                            {{ role }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-lg-6">
                <div
                    class="d-inline"
                    matTooltip="{{ formGroup.controls.activated.disabled ? 'You cannot disable yourself' : '' }}"
                    matTooltipPosition="below"
                >
                    <mat-slide-toggle color="primary" id="activate" (change)="handleUserStatus()" formControlName="activated">
                        {{ ENABLE_USER }}
                    </mat-slide-toggle>
                </div>
            </div>
            <div class="col-lg-6">
                <mat-checkbox id="social-login-only" formControlName="isOAuthOnly">
                    <span [style.color]="disableSocialLoginForAdmin ? 'gray' : 'inherit'">{{ SOCIAL_LOGIN_MESSAGE }}</span></mat-checkbox
                >
            </div>
            <div class="col-lg-6" *ngIf="!disableSocialLoginForAdmin && !isOrgAdminPage">
                <mat-checkbox [disabled]="!formGroup.controls.activated.value" formControlName="sendNotifications">
                    {{ NOTIFY_EMAIL_MESSAGE }}
                </mat-checkbox>
            </div>
            <div class="col-lg-6 {{ formGroup.controls.activated.value ? '' : 'disabled' }}" *ngIf="!formGroup.controls.id.value">
                <mat-checkbox [disabled]="!formGroup.controls.activated.value" formControlName="invite">
                    {{ SEND_ACTIVATION_EMAIL_LABEL }}
                </mat-checkbox>
            </div>
        </div>

        <!-- submit form -->
        <button
            id="add-user"
            type="submit"
            mat-raised-button
            class="btn btn-success float-end"
            [disabled]="!formGroup.valid || formGroup.pristine"
            [promiseBtn]="promiseForBtn"
        >
            save
        </button>
        <button class="btn btn-cancel float-end" type="button" mat-raised-button [promiseBtn]="promiseForBtn" (click)="onCancel()">
            Cancel
        </button>
        <div class="clearfix"></div>
    </form>
</div>
