import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatSortModule } from '@angular/material/sort';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FieldType, Role, UserType } from 'app/common/enums';
import { CustomHttpParams } from 'app/entities/custom-http-params';
import { User } from 'app/entities/user';
import { StorageService } from 'app/services/storage.service';
import { SharedModule } from 'app/shared.module';
import { UserQuery } from 'app/state/user';
import * as _ from 'lodash';

import { Utils } from '../../common/utils';
import { HttpService } from '../../services/http.service';
import { SpinnerModule } from '../spinner/spinner.module';
import { SchoolUserDetailsComponent } from '../user-details/school-user-details.component';
import { UserService } from '../user-details/user.service';
import { BaseUsers } from './base-users';

@Component({
    selector: 'app-table-school-users',
    standalone: true,
    imports: [
        CommonModule,
        SpinnerModule,
        MatPaginatorModule,
        MatInputModule,
        MatTableModule,
        SharedModule,
        MatCheckboxModule,
        MatTooltipModule,
        MatSortModule,
    ],
    templateUrl: './users.component.html',
    providers: [UserService<User>],
})
export class SchoolUsersComponent extends BaseUsers<User> implements OnInit {
    icon = 'person';
    cardTitle = 'School';
    tableId = 'schoolUsersTable';

    constructor(
        protected userQuery: UserQuery,
        router: Router,
        httpService: HttpService,
        dialog: MatDialog,
        storageService: StorageService,
        modalService: NgbModal,
        userService: UserService<User>
    ) {
        super(userQuery, router, httpService, dialog, storageService, modalService, UserType.SchoolUser, userService);
        this.displayedColumns = ['name', 'email', 'activated', 'role', 'sendNotifications', 'lastLogin', 'actions'];
    }

    async ngOnInit() {
        const filterValues = [{ id: 'role', value: [Role.User, Role.Editor, Role.SchoolAdmin], type: FieldType.Dropdown }];
        const userParams: CustomHttpParams = new CustomHttpParams()
            .generateFilters(filterValues)
            .generateFields(['id', 'firstName', 'lastName', 'email', 'title', 'activated', 'role', 'sendNotifications', 'lastLogin']);
        this.tableIsLoading = this.userService.getUsers(userParams).then((users: User[]) => {
            this.users = users;
            this.usersListChanged.emit(this.users);
            this.buildTable(this.users);
        });
    }

    addUser() {
        this.add(SchoolUserDetailsComponent);
    }

    editUser(userInfo: number) {
        this.edit(userInfo, SchoolUserDetailsComponent);
    }
}
