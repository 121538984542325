import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseForm } from 'app/base-form';
import { ModalAction, UserType } from 'app/common/enums';
import { ETUser, OrgUser, User } from 'app/entities/user';
import { emailValidator } from 'app/validators/email.validator';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';

import { Constants } from '../../common/constants';
import { ISelectUserData } from '../../common/interfaces';
import { Colors, Utils } from '../../common/utils';
import { HttpService } from '../../services/http.service';
import { SelectUserDialogComponent } from '../select-user-dialog/select-user-dialog.component';
import { UserService } from './user.service';

declare var $: any;

@Directive()
export abstract class BaseUserDetailsModal<T extends ETUser> extends BaseForm implements OnInit, OnDestroy {
    protected abstract createUser(): T;
    @Input() userId: number;
    @Input() cardTitle: string;
    @Input() shouldAllowSecondaryRole = false;
    @Input() secondaryRoleType: UserType;
    loaded = false;
    title = '';
    roles: string[] = [];
    secondaryRoles: string[];
    userInfo = null;
    noItemSelected = Constants.noItemSelected; // show constant string in html
    requiredTextFieldMinLength = Constants.requiredTextFieldMinLength;
    length50 = Constants.length50;
    length60 = Constants.length60;
    emailMaxLength = Constants.emailMaxLength;

    users: ETUser[] = [];
    isDeletable = false;
    selectUserData: ISelectUserData = { htmlId: 'selectUserEditUser' } as ISelectUserData;
    selectUserSub: Subscription;
    isAbleToManageSysAdmins = false;
    disableSocialLoginForAdmin = false;
    isOrgAdminPage = false;
    SEND_ACTIVATION_EMAIL_LABEL = 'Send activation email';
    SOCIAL_LOGIN_MESSAGE = 'Allow social login only (i.e. Google)';
    NOTIFY_EMAIL_MESSAGE = 'Notify this user via email on form submissions';
    ENABLE_USER = 'Enable this user';

    constructor(
        protected fb: UntypedFormBuilder,
        protected router: Router,
        protected httpService: HttpService,
        protected activeModal: NgbActiveModal,
        protected dialog: MatDialog,
        protected userService: UserService<T>,
        protected userType: UserType
    ) {
        super();
    }

    async ngOnInit() {
        this.userInfo = Utils.getUserInfoFromToken();
        if (this.userInfo.isAbleToManageSysAdmins() && this.router.url.indexOf('system-admin') !== -1) {
            this.isAbleToManageSysAdmins = true;
        }
        this.roles = await this.userService.getRoles(this.userType);
        if (this.shouldAllowSecondaryRole) {
            if (this.secondaryRoleType && this.userType !== this.secondaryRoleType) {
                this.secondaryRoles = await this.userService.getRoles(this.secondaryRoleType);
            } else {
                this.secondaryRoles = this.roles;
            }
        }
        if (this.userId) {
            this.title = `Edit ${this.cardTitle} User`;
            const user: T = await this.userService.getUser(this.userId, this.userType);
            this.createForm(user);
            try {
                this.isDeletable = await this.userService.deletable(this.userId, this.userType, false);
            } catch {
                this.isDeletable = false;
            }
        } else {
            this.title = `Add ${this.cardTitle} User`;
            const user: T = this.createUser();
            this.createForm(user);
        }
        this.formGroup.controls.invite.setValue(false);
        // Should disable isOAuthOnly if super admin is editing or creating system user(s) or activate button is turned off
        if (this.disableSocialLoginForAdmin || !this.formGroup.controls.activated.value) {
            this.formGroup.controls.isOAuthOnly.disable();
        } else {
            this.formGroup.controls.isOAuthOnly.enable();
        }
    }

    ngOnDestroy() {
        if (this.selectUserSub) {
            this.selectUserSub.unsubscribe();
        }
        super.ngOnDestroy();
    }

    protected createForm(user?: T) {
        this.formGroup = this.fb.group({
            id: [user.id],
            firstName: [
                user.firstName,
                Validators.compose([
                    Validators.required,
                    Validators.minLength(this.requiredTextFieldMinLength),
                    Validators.maxLength(this.length50),
                ]),
            ],
            lastName: [
                user.lastName,
                Validators.compose([
                    Validators.required,
                    Validators.minLength(this.requiredTextFieldMinLength),
                    Validators.maxLength(this.length50),
                ]),
            ],
            email: [
                { value: user.email, disabled: user.id },
                Validators.compose([Validators.required, emailValidator, Validators.maxLength(this.emailMaxLength)]),
            ],
            title: [
                user.title,
                Validators.compose([
                    Validators.required,
                    Validators.minLength(this.requiredTextFieldMinLength),
                    Validators.maxLength(this.length60),
                ]),
            ],
            role: [
                { value: this.isAbleToManageSysAdmins ? User.ROLE_SYSADMIN : user.role, disabled: this.userInfo.id === user.id },
                Validators.required,
            ],
            sendNotifications: [user?.sendNotifications ?? false],
            isOAuthOnly: [this.disableSocialLoginForAdmin ? true : user?.isOAuthOnly ?? false],
            activated: { value: user?.activated ?? false, disabled: this.userInfo.id === this.userId },
            invite: [false],
        });
        if (this.shouldAllowSecondaryRole) {
            const secondaryRole = new FormControl({
                value: user.roles?.filter(r => r !== user.role).shift(),
                disabled: this.userInfo.id === user.id,
            });
            this.formGroup.addControl('secondaryRole', secondaryRole);
        }
        this.loaded = true;
        this.listenToFormChanges();
    }

    onSubmit() {
        this.submit().catch(console.error);
    }

    onCancel() {
        this.activeModal.close({ action: ModalAction.Cancel });
    }

    handleUserStatus() {
        const isAdmin = this.userInfo.isSysAdmin() || this.userInfo.isOrgAdmin() || this.userInfo.isSchoolAdmin();
        // We want to clean out all values
        this.formGroup.controls.invite.setValue(false);
        this.formGroup.controls.sendNotifications.setValue(false);
        // For isOAuthOnly, we want to clean out the value only when the user is not an admin
        this.formGroup.controls.isOAuthOnly.setValue(this.disableSocialLoginForAdmin && isAdmin);

        this.formGroup.controls.isOAuthOnly.disable();
        // Override isOAuthOnly if this is school user table & being activated
        if (this.formGroup.controls.activated.value && !this.disableSocialLoginForAdmin && isAdmin) {
            this.formGroup.controls.isOAuthOnly.enable();
        }
    }

    protected doSubmit(): Promise<void> {
        if (!this.formGroup.value.activated && this.isDeletable && this.formGroup.value.id) {
            return this.openReassignUserDialog();
        } else {
            return this.saveUser().then((user: T) => {
                const action = this.userId ? ModalAction.Update : ModalAction.Create;
                this.activeModal.close({ action, user });
                if (this.formGroup.value.activated && this.formGroup.value.invite) {
                    return this.inviteUser(this.formGroup.value.email);
                } else {
                    return Promise.resolve();
                }
            });
        }
    }

    private async saveUser(): Promise<T> {
        const isNewUser = !this.formGroup.value.id;

        const { secondaryRole, ...userData } = this.formGroup.value;
        // if secondary roles are not allowed, don't set `roles`
        if (secondaryRole !== undefined) {
            const roles = [userData.role];
            // only add if something is selected
            if (secondaryRole !== null) {
                roles.push(secondaryRole);
            }
            userData.roles = roles;
        }

        // If isOAuthOnly is disabled, we want still to send the value to the backend
        const formData = { ...userData, isOAuthOnly: this.formGroup.controls.isOAuthOnly.value };

        const user = isNewUser ? await this.userService.addUser(formData) : await this.userService.updateUser(formData);
        Utils.showSuccessNotification(isNewUser ? `${this.cardTitle} user successfully created` : '');
        return user;
    }

    private openReassignUserDialog(): Promise<any> {
        return new Promise<void>((resolve, reject) => {
            Swal.fire({
                title: 'Are you sure?',
                html: 'This user will no longer be active. You need to re-assign any new tasks or events to another user to continue.\
                        <br><br>\
                        <button class="btn btn-cancel cancel">Cancel</button>\
                        <button class="btn btn-success select-user">Select User</button>',
                type: 'warning',
                showConfirmButton: false,
                onOpen: swalEditUser => {
                    $(swalEditUser)
                        .find('.select-user')
                        .off()
                        .click(e => {
                            Swal.close();
                            this.httpService
                                .getAuth('users/activated')
                                .then((users: T[]) => {
                                    _.remove(users, (u: T) => u.id === this.formGroup.value.id);
                                    this.selectUserData.users = users;
                                    const selctUsermDialogRef = this.dialog.open(
                                        SelectUserDialogComponent,
                                        SelectUserDialogComponent.getDialogConfig(this.selectUserData)
                                    );
                                    this.selectUserSub = selctUsermDialogRef.afterClosed().subscribe(id => {
                                        if (id) {
                                            this.userService.reassignUser(this.formGroup.value.id, id).then(() => {
                                                this.saveUser().then((user: T) => {
                                                    this.activeModal.close({
                                                        action: ModalAction.Update,
                                                        user,
                                                        targetUserId: id,
                                                        sourceUserId: this.formGroup.value.id,
                                                    });
                                                    resolve();
                                                });
                                            });
                                        } else {
                                            reject();
                                        }
                                    });
                                })
                                .catch(err => {
                                    reject(err);
                                });
                        });
                    $(swalEditUser)
                        .find('.cancel')
                        .off()
                        .click(e => {
                            Swal.close();
                            reject(e);
                        });
                },
            });
        });
    }

    private inviteUser(email: string): Promise<void> {
        return this.httpService
            .postAuth('users/invite', { email })
            .then(() => {
                Utils.showNotification('Invite message has been sent to user', Colors.success);
                return Promise.resolve();
            })
            .catch(err => console.log(err));
    }
}
