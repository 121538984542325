import { Injectable } from '@angular/core';
import { OrganisationListDTO } from 'app/common/dto/organisation-list.dto';
import { Utils } from 'app/common/utils';
import { FilterValue } from 'app/components/filter-constellation/interfaces/filter-value';
import { Campus } from 'app/entities/campus';
import { CustomHttpParams } from 'app/entities/custom-http-params';
import { Organisation } from 'app/entities/organisation';
import { HttpService } from 'app/services/http.service';

import { DuplicateEnquiriesPerSchoolDTO, OrgFamilyDetails, UniqStudentsDTO } from './enquiries/duplicates/duplicate-enquiries.dto';
import { OrgDuplicateEnquiryDetails } from './enquiries/duplicates/per-school/schools-list/school-details-dialog/school-details-dialog.component';
import { KeyMetricsDTO, OrgDTO } from './org-details/org.dto';

@Injectable()
export class OrgService {
    constructor(private httpService: HttpService) {}

    getOrgs(httpParams: CustomHttpParams): Promise<(Partial<Organisation> | OrganisationListDTO)[]> {
        return this.httpService.getAuth(`orgs?${Utils.toStringEncoded(httpParams)}`);
    }

    getOrg(id: number, orgParams?: CustomHttpParams): Promise<Organisation> {
        return this.httpService.getAuth<Organisation>(`orgs/${id}${orgParams ? '?' + Utils.toStringEncoded(orgParams) : ''}`);
    }

    getKeyMetrics(id: number, filterValues: FilterValue[], searchText: string): Promise<KeyMetricsDTO> {
        const queryParams: CustomHttpParams = new CustomHttpParams().generateFilters(filterValues).set('search', searchText);
        return this.httpService.getAuth(`orgs/${id}/key-metrics${queryParams ? '?' + Utils.toStringEncoded(queryParams) : ''}`);
    }

    getOrgSchoolCampuses(id: number, queryParams: CustomHttpParams): Promise<Campus[]> {
        return this.httpService.getAuth(`orgs/${id}/school-campuses${queryParams ? '?' + Utils.toStringEncoded(queryParams) : ''}`);
    }

    addOrg(data: OrgDTO): Promise<Organisation> {
        return this.httpService.postAuth('orgs', data);
    }

    updateOrg(id: number, data: OrgDTO): Promise<{ token: string }> {
        return this.httpService.putAuth(`orgs/${id}`, data);
    }

    delete(id: number): Promise<void> {
        return this.httpService.deleteAuth(`orgs/${id}`);
    }

    getOrgFamilies(id: number, queryParams: CustomHttpParams): Promise<UniqStudentsDTO> {
        return this.httpService.getAuth(
            `orgs/${id}/duplicate-enquiries/uniq-students${queryParams ? `?${Utils.toStringEncoded(queryParams)}` : ''}`
        );
    }

    getOrgFamilyDetails(id: number, commaSeparatedEnquiryIds: string): Promise<OrgFamilyDetails[]> {
        return this.httpService.getAuth(`orgs/${id}/families/${commaSeparatedEnquiryIds}`);
    }

    getOrgDuplicateEnquiryDetails(orgId: number, enquiryId: number): Promise<OrgDuplicateEnquiryDetails> {
        const queryParams: CustomHttpParams = new CustomHttpParams().generateFields(['id', 'studentName', 'yearLevel', 'status', 'stage']);
        return this.httpService.getAuth(
            `orgs/${orgId}/enquiries/${enquiryId}${queryParams ? `?${Utils.toStringEncoded(queryParams)}` : ''}`
        );
    }

    getOrgEnquiryContacts(orgId: number, enquiryId: number, limit?: number): Promise<OrgDuplicateEnquiryDetails> {
        const queryParams: CustomHttpParams = new CustomHttpParams().generateFields(['contactName']).generateLimit(limit);
        return this.httpService.getAuth(
            `orgs/${orgId}/enquiries/${enquiryId}/contacts${queryParams ? `?${Utils.toStringEncoded(queryParams)}` : ''}`
        );
    }

    getOrgDuplicateEnquiriesByCampus(id: number, queryParams: CustomHttpParams): Promise<DuplicateEnquiriesPerSchoolDTO> {
        return this.httpService.getAuth(
            `orgs/${id}/duplicate-enquiries/by-campus${queryParams ? `?${Utils.toStringEncoded(queryParams)}` : ''}`
        );
    }
}
